.custom_bannerr{
    padding: 0;
    background-size: cover;
    background-position: center;
    position: relative;
}

.custom_bannerr::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #a1c849;
}

.custom_bannerr .banner-before{
    content:"";
    position:absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.custom_bannerr .banner-overlay { /* Styling for overlay */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
}

.custom_bannerr .container{
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.custom_bannerr .column{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.custom_bannerr .banner_text h3{
    font-size: 36px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.custom_bannerr h3{
    margin-top: 20px;
    margin-bottom: 10px;
}
/* @media (min-width:768px){
    .column{
        float: left;
    }
} */