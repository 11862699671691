.single_sec_flat {
    background: #f6f6f6;
}

.tour_search #banner {
    position: relative;
    background-color: #fff;
}

.tour_search .custom_banner {
    padding: 0;
    background-size: cover;
    background-position: center;
    position: relative;
}

.tour_search .listPackagesBanner {
    margin-bottom: 30px;
}

.tour_search .custom_banner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #00000078;
}

.tour_search .banner-parallax {
    position: relative;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    min-height: auto;
}

.tour_search .banner-parallax>.bg-element {
    /* background-size: cover; */
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat top center / cover;
    transition: opacity 0.5s;
}

.tour_search .listPackagesBanner .banner-parallax img {
    object-fit: cover;
    object-position: center;
}

.tour_search .banner-parallax img {
    width: 100%;
    display: none;
}

.tour_search img {
    max-width: 100%;
    max-height: 100%;
}

.tour_search .listPackagesBanner .banner_search.tour_package_search {
    min-height: 200px;
    padding: 0 !important;
}

.tour_search .banner_search.tour_package_search {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    min-height: 90vh;
    display: flex;
    align-items: center;
}

.tour_search .banner_search.tour_package_search:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #0000003b;
}

.tour_search .tour_package_search .banner_search_box {
    margin-top: 40px;
}

.tour_search .listPackagesBanner h1 {
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
    color: #fff;
}

.tour_search .tour_package_search .banner_search_box form.search_form #custom-search-input {
    float: left;
    width: 100%;
    border-radius: 5px;
}

.tour_search .d-flex {
    display: flex;
}

.tour_search .tour_package_search .banner_search_box form.search_form .input_field {
    position: relative;
    width: calc(100% - 116px);
    float: left;
}

.tour_search .tour_package_search .banner_search_box form.search_form .input_field input {
    background: #fff;
    width: 100%;
    border: 0px;
    padding: 15px 28px 15px 15px;
    font-size: 18px;
    line-height: 21px;
}

.tour_search .iconnn {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 21px;
    color: #37b1c2;
}

.tour_search .tour_package_search .banner_search_box form.search_form .search_btn {
    float: left;
}

.tour_search .tour_package_search .banner_search_box form.search_form .search_btn button {
    background: #a1c849;
    border: 0px;
    outline: none;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    padding: 15px 30px 15px;
}




.tour_search .package_item {
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.tour_search .trip_package_content {
    display: flex;
    padding: 15px;
}

.tour_search .trip_pic_col {
    /* flex: 0 0 33.33%; */
    position: relative;
}

.tour_search .trip_pic_col .pack_img img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.tour_search .pack_nts {
    position: absolute;
    top: 12px;
    left: 0;
    background: #fff;
    padding: 3px 10px;
    border-radius: 0 2px 2px 0;
}

.tour_search .pack_nts span {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    font-weight: bold;
}

/* .trip_pic_detail {
    width: 66.67%;
} */

.tour_search .trip_type {
    border: 1px solid #a1c849;
    display: inline-flex;
    align-items: center;
    height: 22px;
    font-size: 11px;
    overflow: hidden;
    color: #a1c849;
    border-radius: 3px;
    margin-bottom: 5px;
    padding: 0 5px;
    line-height: 25px;
    text-transform: uppercase;
    font-weight: 600;
}

/* .trip_type::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #a1c849 transparent transparent transparent;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .trip_type::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
  } */

  .tour_search .trip_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tour_search .trip_name .pkg_name {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    margin: 0px;
    color: #337ab7;
}


.tour_search .p_price {
    /* flex: 0 0 220px; */
    text-wrap: nowrap;
    text-align: right;
}

.tour_search .p_price .price {
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    color: #a1c849;
}

.tour_search .p_price p {
    font-size: 12px;
    margin-bottom: 0;
}

.tour_search .trip_address {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tour_search .trip_address span {
    font-size: 12px;
    line-height: 16px;
    color: #666;
    margin: 5px 0px 0 0;
    display: flex;
    align-items: baseline;
}

.tour_search .trip_address span .mapicon {
    color: #37b1c2;
    margin-right: 4px;
}

.tour_search .btn-book-now {
    background: #a1c849;
    color: #fff;
    margin-top: 5px;
    white-space: nowrap;
}

.tour_search .trip_tags {
    margin: 10px 0 0;
}

.tour_search .trip_tags ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.tour_search .trip_tags ul li {
    font-size: 11px;
    line-height: 16px;
    padding: 2px 8px;
    background: #eee;
    color: #888;
    border-radius: 4px;
    margin: 0px 4px 0 0;
    text-indent: 0;
}



.tour_search .custom_sidebar {
    border-radius: 10px !important;
    padding: 10px;
}

.tour_search .custom_sidebar h3 {
    font-size: 21px;
    line-height: 24px;
    margin: 0px 0px 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 600;
}

.tour_search .custom_sidebar h3 button {
    font-size: 14px;
    line-height: 16px;
    float: right;
    font-weight: normal;
    background: #a1c849;
    padding: 5px 8px;
    border-radius: 2px;
    color: #fff;
    border: none;
}

.tour_search .btn_gradient,
.tour_search .btn_gradient:focus {
    background-image: linear-gradient(to right, #a1c849, #759136);
}

.tour_search .custom_sidebar .box-widget .box-title {
    font-size: 13px;
    line-height: 16px;
    margin: 0px 0px 6px;
    font-weight: 600;
    text-transform: uppercase;
}

.tour_search .custom_sidebar .box-widget+.box-widget {
    padding-top: 12px;
    margin-top: 10px;
}

.tour_search .custom_sidebar .box-widget .box-title {
    font-size: 14px;
    line-height: 18px;
    margin: 0px 0px 6px;
}

.tour_search .custom-slider {
    /* width: 100%; */
    padding-top: 15px;
    /* padding: 15px 8px 0px; */
    height: 4px;
    border: none;
    /* background-color: #e5e5e5; */
}

.tour_search .custom-slider .rc-slider-track {
    background-color: #a1c849;
}

.tour_search .custom-slider .rc-slider-handle {
    outline: none;
    /* top: 50%; */
    transform: translateY(-50%);
    /* width: 18px; */
    /* height: 18px; */
    /* border-radius: 999px; */
    border: 2px solid #a1c849;
    background-color: #fff;
}

.tour_search .custom-slider .rc-slider-handle::activ {
    background-color: #fff;
    border: none;
}

.tour_search .slider-value {
    margin-top: 20px;
    font-size: 13px;
    background: #f6f6f6;
    padding: 3px 5px;
    pointer-events: none;
    border: none;
    height: auto;
    line-height: initial;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tour_search .list-checkboxes li {
    display: flex;
}

.tour_search .checkbox-default>span {
    color: #000;
}

.tour_search .filter_close {
    font-size: 26px;
}

.tour_search .clearfilter {
    font-size: 14px;
    line-height: 16px;
    float: right;
    font-weight: normal;
    background: #a1c849;
    padding: 5px 8px;
    border-radius: 2px;
    color: #fff;
    border: none;
}





@media (min-width: 768px) {
    /* .banner_search.tour_package_search {
        padding: 100px 0px 80px;
    } */

    .tour_search .label-container.checkbox-default {
        font-size: 13px;
        line-height: 21px;
    }

    .tour_search .custom_sidebar .filter_close,
    .tour_search .custom_sidebar .applyfilter_btn {
        display: none;
    }
}

@media (min-width: 992px) {
    .tour_search .flight_list_sec .row .cus_col_9 {
        padding-left: 5px;
    }

    .tour_search .flight_list_sec .row .cus_col_3 {
        padding-right: 5px;
        margin-bottom: 20px;
    }

    .tour_search .custom_sidebar {
        padding: 16px 12px;
        box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 5px;
    }


}

@media (max-width: 992px) {
    .tour_search .package-container {
        padding: 0;
    }

    .tour_search .card-body {
        padding: 0;
    }

    .tour_search .pack_nts span {
        font-size: 10px;
    }

    .tour_search .trip_type {
        height: 20px;
        font-size: 10px;
        line-height: 20px;
    }

    .tour_search .trip_name .pkg_name {
        font-size: 14px;
        line-height: 18px;
    }

    .tour_search .p_price .price {
        font-size: 18px;
        line-height: 20px;
    }

    .tour_search .p_price p {
        font-size: 10px;
    }

    .tour_search .trip_address span {
        font-size: 10px;
        line-height: 14px;
    }

    .tour_search .btn-book-now {
        font-size: 12px;
        padding: 5px 10px;
    }

    .tour_search .trip_tags ul li {
        font-size: 10px;
        line-height: 14px;
        padding: 2px 6px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
}

/* Media queries for small-sized screens */
@media (max-width: 768px) {
    /* .sidebar_filter{
        display: none;
    } */

    .tour_search .pack_nts span {
        font-size: 9px;
    }

    .tour_search .trip_type {
        height: 18px;
        font-size: 9px;
        line-height: 18px;
    }

    .tour_search .trip_name .pkg_name {
        font-size: 12px;
        line-height: 16px;
    }

    .tour_search .p_price .price {
        font-size: 16px;
        line-height: 18px;
    }

    .tour_search .trip_address span {
        font-size: 9px;
        line-height: 12px;
    }

    .tour_search .btn-book-now {
        font-size: 10px;
        padding: 4px 8px;
    }

    .tour_search .trip_tags ul li {
        font-size: 9px;
        line-height: 12px;
        padding: 2px 4px;
        margin-right: 2px;
        margin-bottom: 2px;
    }
}