.rtb {
    position: relative;
}

.frmdfs {
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.d-flex {
    /* display: -ms-flexbox; */
    display: flex;
}

.wd40 {
    width: 40%;
}

.form_input_trs {
    margin-bottom: 20px;
}

.form_input_trs>label {
    font-weight: 500;
    font-size: 16px;
}

.booking_search_form .searchcity .input-box {
    position: relative;
}

.form_rtv .css-1jqq78o-placeholder {
    border: 1px solid #ddd !important;
    width: 100% !important;
    font-size: 16px !important;
    padding: 12px 12px !important;
    border-bottom: 2px solid #a1c846 !important;
    border-radius: 5px 5px 0px 0px !important;
    font-weight: 300 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-error {
    color: #ff0000;
    font-size: 12px;
    line-height: 16px;
}

.excng {
    width: 35px;
    font-size: 18px;
    color: #a1c849;
    cursor: pointer;
    text-align: center;
}

.btn_rm {
    text-align: center;
}

.btn_rm>button {
    width: 100%;
    padding: 10px 10px;
    font-size: 18px;
    background: #a1c849;
    color: #fff;
    border: 1px solid #a1c849;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

@media only screen and (min-width: 990px) {
    .btn_rm>button {
        width: 300px;
    }
}

@media only screen and (max-width: 640px) {
    .frmdfs {
        flex-wrap: wrap;
    }

    .form_input_trs {
        width: 100%;
    }

    .excng {
        float: right;
        display: block;
        width: 100%;
        text-align: center;
    }

    .excng>svg {
        transform: rotate(90deg);
    }

    .nonemd {
        display: none;
    }
}