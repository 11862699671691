.footer {
  background-color: #f2ffd4;
  font-family: "Montserrat", sans-serif !important;
  color: #333;
  padding: 20px 0;
}

footer.footer {
  margin-top: 80px;
}

.footer .footer-section {
  margin-bottom: 30px;
}

.footer .footer-section h3 {
  font-size: 20px;
  font-weight: 700 !important;
  color: #333;
  margin-bottom: 15px;
}

.footer .footer-links {
  list-style: none;
  padding: 0;
}

.footer .footer-links li {
  /* margin-bottom: 10px; */
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
}

.footer .footer-links a {
  color: #333;
  text-decoration: none;
}

.footer .contact-info {
  margin-bottom: 15px;
}

.footer .contact-info span {
  display: block;
  /* margin-bottom: 5px; */
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
}

.footer .contact-info span a {
  color: #333;
  text-decoration: none;
}

.footer .contact-info .icon {
  margin-right: 3px;
}

.footer .social-icons {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.footer .social-icons a {
  /* color: #333; */
  /* font-size: 18px; */
  background: #c5d79b;
  padding: 6px 10px;
  border-radius: 5px;
  color: #403f3f;
  font-size: 18px;
  width: 40px;
  height: 40px;
  text-align: center;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .payment-cards {
  margin-top: 20px;
}

.footer .payment-cards span {
  display: inline-block;
  /* width: 30px;
    height: 20px; */
  background-size: contain;
  /* margin-right: 10px; */

  padding: 1px;
  margin: 3px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.footer .footer-bottom {
  text-align: center;
  border-top: 1px solid #e4f1c7;
  margin-top: 10px;
  padding-top: 10px;
}

.footer .footer-bottom p {
  color: #333;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

.visa,
.master,
.american {
  float: left;
  height: 37px !important;
  width: 57px !important;
  margin-right: 10px;
}

.paypal,
.rupay,
.pci {
  float: left;
  height: 37px;
  width: 84px;
}

.iata{
  /* float: left; */
  height: 85px;
  /* width: 220px; */
  width: 100%;
  margin: 5px;
}

.visa {
  background-image: url(/public/Images/visa.png);
}

.master {
  background-image: url(/public/Images/master.png);
}

.american {
  background-image: url(/public/Images/american.png);
}

.paypal {
  background-image: url(/public/Images/paypal.png);
}

.rupay {
  background-image: url(/public/Images/rupay.png);
}

.pci {
  background-image: url(/public/Images/pci.png);
}

.iata{
  background-image: url(/public/Images/iata-accredited-agent.png);
  background-color: white;
  background-repeat: no-repeat;
}

.footer .overlap_box {
  display: flex;
  align-items: center;
  background: #9dc541;
  padding: 30px;
  border-radius: 10px;
  margin-top: -75px;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}

.footer .overlap_box > div {
  width: 100%;
}

.footer .overlap_box h4 {
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.footer .overlap_box form {
  display: flex;
  width: 100%;
}

.footer .overlap_box input {
  height: 50px;
  width: 80% !important;
  padding-left: 18px;
  font-size: 16px;
  border: 0;
  border-radius: 50px 0 0 50px;
}

.footer .overlap_box .btn.btn-primary {
  background: #336d85;
  border-color: #336d85;
  width: 20% !important;
  font-size: 16px;
  padding: 12px 10px !important;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0 50px 50px 0;
}

@media (max-width: 768px) {
  .footer .overlap_box input {
    width: 60% !important;
  }

  .footer .overlap_box .btn.btn-primary {
    width: 40% !important;
    font-size: 13px !important;
  }
}
