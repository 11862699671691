.travelagent {
  font-family: 'Montserrat', sans-serif !important;
    background-image: linear-gradient(to right, #a1c849, #fef6f6);
    padding: 40px 0px;
    position: relative;
  }
  
  .travelagent::before{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: url(/public/Images/bgmap.png)no-repeat;
    background-position: center;
    background-size: cover;
  }

  .travelagent .container{
    margin-right: auto;
    margin-left: auto;
  }

  .travelagent .bannertxt h1 {
    margin: 0 0 30px 0;
    font-weight: bold;
    color: #567215;
    text-shadow: 2px 2px 2px #fff;
    font-size: 55px;
  }
  
  .travelagent .col_xs_480{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .travelagent .agent_form {
    /* background-color: #f5f5f5;
    padding: 30px;
    border-radius: 5px; */
    padding: 20px;
    border-radius: 7px;
  }
  
  .travelagent .agent_form h1 {
    font-size: 34px;
    line-height: 38px;
    margin: 0px 0px 25px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }
  
  .travelagent .form-group{
    margin-bottom: 15px;
  }

  .travelagent .form-group span{
    width: 35px;
    height: 35px;
    position: absolute;
    left: 10px;
    right: 0;
    background: #a1c849;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    top: 7px;
  }

  .travelagent .iconbc {
    background-color: #ccc;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    color: #333;
  }
  
  .travelagent .frmb {
    /* display: flex;
    align-items: center; */
    position: relative;
  }
  
  .travelagent .frmb input{
    padding: 14px 10px 14px 52px;
    height: auto;
    border-radius: 50px !important;
  }

  /* .frmb input[type="text"],
  .frmb input[type="password"] {
    flex: 1;
    border-radius: 0 5px 5px 0;
  } */
  .travelagent .form-control{
    outline: none;
    box-shadow: none;
  }
  .travelagent .pass_eyeswitch {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .travelagent .checkbox{
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
  }
  .travelagent .checkbox-default{
    padding: 0px;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }

  .travelagent .checkbox .form-check-input {
    margin-right: 5px;
    
  }
  
  /* .forgot_link {
    margin-top: 10px;
    display: inline-block;
    float: right;
  } */
  
  .travelagent .forgot-link{
    font-size: 13px;
    line-height: 16px;
    color: #255468 ;
    text-decoration: underline;
  }
  

  .travelagent .form_btn button {
    /* margin-top: 20px; */
    color: #fff;
    background: #255468;
    font-size: 16px;
    line-height: 21px;
    display: block;
    width: 100%;
    border: 0px;
    border-radius: 50px;
    padding: 13px 10px;
    text-transform: uppercase;
    font-weight: normal;
  }
  
  .travelagent .cus_txt{
    text-align: center;
    margin: 20px 0px;
  }
  .travelagent .cus_txt p {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: normal;
    margin: 0px;
  }
  
  .travelagent .cus_txt a {
    color: #fff;
    background: #a1c849;
    display: inline-block;
    padding: 6px 14px;
    border-radius: 50px;
    border: 1px solid #8ab132;
    text-decoration: none;
  }
  
  .travelagent .rps{
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width:768px){
    .travelagent .bannertxt h1{
      font-size: 22px;
    }
  }