.homepg .ast-container {
  max-width: 100%;
}

.homepg .ast-container,
.homepg .ast-container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.homepg .ast-page-builder-template .site-content > .ast-container {
  max-width: 100%;
  padding: 0;
}

.homepg #primary,
.homepg #secondary {
  width: 100%;
}

.homepg #primary {
  margin: 4em 0;
}

.homepg .ast-page-builder-template .site .site-content #primary {
  padding: 0;
  margin: 0;
}

.homepg main,
.homepg nav {
  display: block;
}

.homepg .entry-header {
  margin-bottom: 1.2em;
  word-wrap: break-word;
}

.homepg .page .entry-header {
  margin-bottom: 1.5em;
}

.homepg .ast-page-builder-template .entry-header {
  margin-top: 4em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.homepg .ast-page-builder-template .entry-header {
  padding-left: 0;
}

.homepg .ast-single-post .entry-header.ast-header-without-markup,
.homepg .ast-single-post .entry-header.ast-no-title.ast-no-thumbnail {
  margin-bottom: 0;
}

.homepg .ast-page-builder-template .entry-header.ast-header-without-markup {
  margin-top: 0;
  margin-bottom: 0;
}

.homepg .entry-content {
  word-wrap: break-word;
}

.homepg .entry-content > :last-child,
.homepg .entry-summary > :last-child,
.homepg .page-content > :last-child {
  margin-bottom: 0;
}

.homepg .elementor *,
.homepg .elementor :after,
.homepg .elementor :before {
  box-sizing: border-box;
}

.homepg .elementor-element {
  --flex-direction: initial;
  --flex-wrap: initial;
  --justify-content: initial;
  --align-items: initial;
  --align-content: initial;
  --gap: initial;
  --flex-basis: initial;
  --flex-grow: initial;
  --flex-shrink: initial;
  --order: initial;
  --align-self: initial;
  flex-basis: var(--flex-basis);
  flex-grow: var(--flex-grow);
  flex-shrink: var(--flex-shrink);
  order: var(--order);
  align-self: var(--align-self);
}

.homepg .elementor-section {
  position: relative;
}

.homepg .elementor-element,
.homepg .elementor-lightbox {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 44px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

.homepg .elementor-element {
  --widgets-spacing: 20px;
}

.homepg .elementor-20 .elementor-element.elementor-element-352b6c3 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 40px 0;
}

.homepg .elementor-20
  .elementor-element.elementor-element-352b6c3:not(
    .elementor-motion-effects-element-type-background
  ),
.homepg .elementor-20
  .elementor-element.elementor-element-352b6c3
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url(https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/06/hero-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepg .elementor .elementor-background-overlay,
.homepg .elementor .elementor-background-slideshow {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.homepg .elementor-20
  .elementor-element.elementor-element-352b6c3
  > .elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(
    135deg,
    var(--e-global-color-astglobalcolor0) 0%,
    var(--e-global-color-astglobalcolor7) 100%
  );
  opacity: 0.4;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.homepg .elementor-section .elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.homepg .elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}

.homepg .elementor-section.elementor-section-items-middle > .elementor-container {
  align-items: center;
}

.homepg .elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1200px;
}

.homepg .elementor-20
  .elementor-element.elementor-element-352b6c3
  > .elementor-container {
  min-height: 80vh;
}

.homepg .elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
}

.homepg .elementor-element,
.homepg .elementor-lightbox {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 44px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

.homepg .elementor-element {
  --widgets-spacing: 20px;
}

.homepg .elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.homepg .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.homepg .elementor-20
  .elementor-element.elementor-element-19d0ec4
  > .elementor-element-populated {
  padding: 0% 40% 0% 0%;
}

.homepg .elementor-widget-wrap > .elementor-element {
  width: 100%;
  margin-bottom: 10px;
}

.homepg .elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.homepg .elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.homepg .elementor-element .elementor-widget-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
}

.homepg .elementor-20
  .elementor-element.elementor-element-5e011cd
  > .elementor-widget-container {
  padding: 0 0 20px;
}

.homepg h6,
.homepg .entry-content h6 {
  font-size: 15px;
  font-size: 0.88235294117647rem;
  line-height: 1.25em;
  font-family: "Poppins", sans-serif;
}

.homepg .elementor-widget-heading .elementor-heading-title {
  margin: 0;
}

.homepg .elementor-widget-heading h6.elementor-heading-title {
  line-height: 1.25em;
}

.homepg .elementor-20
  .elementor-element.elementor-element-5e011cd
  .elementor-heading-title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.homepg .elementor-element .elementor-widget-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
}

.homepg .elementor-widget-heading .elementor-heading-title {
  margin: 0;
}
.homepg .elementor-widget-heading h1.elementor-heading-title {
  line-height: 1.4em;
}

.homepg .elementor-20
  .elementor-element.elementor-element-4cd96e8
  .elementor-heading-title {
  color: #fff;
}

.homepg .elementor-20 .elementor-element.elementor-element-3fbecc2 {
  color: #f2f2f2;
}

.homepg .elementor-20
  .elementor-element.elementor-element-3fbecc2
  > .elementor-widget-container {
  padding: 0 144px 0 0;
}

.homepg p,
.homepg .entry-content p {
  margin-bottom: 1em;
}

.homepg h1,
.homepg .entry-content h1 {
  font-size: 80px;
  font-size: 4.7058823529412rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.4em;
}

.homepg .elementor-button {
  display: inline-block;
  line-height: 1;
  background-color: #69727d;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all 0.3s;
}

.homepg .elementor a {
  box-shadow: none;
  text-decoration: none;
}

.homepg .elementor-button-wrapper .elementor-button {
  border-style: solid;
  text-decoration: none;
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.homepg .elementor-button-wrapper .elementor-button {
  border-color: var(--ast-global-color-0);
  background-color: rgba(62, 35, 209, 0);
}

.homepg .wp-block-button .wp-block-button__link,
.homepg .elementor-button-wrapper .elementor-button,
.homepg .elementor-button-wrapper .elementor-button:visited {
  color: var(--ast-global-color-0);
}

.homepg .homepg .elementor-button-wrapper .elementor-button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-size: 0.94117647058824rem;
  line-height: 1em;
  text-transform: capitalize;
}

.homepg body .elementor-button.elementor-size-sm,
.homepg body .elementor-button.elementor-size-xs,
.homepg body .elementor-button.elementor-size-md,
.homepg body .elementor-button.elementor-size-lg,
.homepg body .elementor-button.elementor-size-xl,
.homepg body .elementor-button {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-top: 15px;
  padding-right: 23px;
  padding-bottom: 15px;
  padding-left: 23px;
}

.homepg .elementor-20 .elementor-element.elementor-element-3640939 .elementor-button {
  fill: #000;
  color: #000;
  background-color: #fff;
  border-style: solid;
  border-color: #fff;
}

.homepg .elementor-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.homepg .elementor-button span {
  text-decoration: inherit;
}

.homepg .elementor-button-text {
  flex-grow: 1;
  order: 10;
  display: inline-block;
}

.homepg .elementor-button span {
  text-decoration: inherit;
}

@media (min-width: 544px) {
  .homepg .ast-container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .homepg .elementor-column.elementor-col-100,
  .homepg .elementor-column[data-col="100"] {
    width: 100%;
  }
}

@media (min-width: 922px) {
  .homepg .ast-container {
    max-width: 1240px;
  }

  .homepg .site-content .ast-container {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .homepg .elementor-section .elementor-container {
    flex-wrap: wrap;
  }

  .homepg .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1024px;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-352b6c3
    > .elementor-container {
    min-height: 70vh;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-19d0ec4
    > .elementor-element-populated {
    padding: 0 64px 0 0;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-3fbecc2
    > .elementor-widget-container {
    padding: 0 80px 0 0;
  }
}

@media (max-width: 921px) {
  .homepg .site-content .ast-container {
    flex-direction: column;
  }

  .homepg #primary,
  .homepg #secondary {
    padding: 1.5em 0;
    margin: 0;
  }

  .homepg .ast-theme-transparent-header #primary,
  .homepg .ast-theme-transparent-header #secondary {
    padding: 0;
  }

  .homepg h6,
  .homepg .entry-content h6 {
    font-size: 15px;
    font-size: 0.88235294117647rem;
  }

  .homepg h1,
  .homepg .entry-content h1 {
    font-size: 64px;
  }

  .homepg .elementor-button-wrapper .elementor-button.elementor-size-sm,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-xs,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-md,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-lg,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-xl,
  .homepg .elementor-button-wrapper .elementor-button {
    padding-top: 15px;
    padding-right: 23px;
    padding-bottom: 15px;
    padding-left: 23px;
  }
}

@media (max-width: 767px) {
  .homepg .elementor-20 .elementor-element.elementor-element-352b6c3 {
    padding: 24px;
  }

  .homepg .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 767px;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-352b6c3
    > .elementor-container {
    min-height: 100vh;
  }

  .homepg .elementor-column {
    width: 100%;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-19d0ec4
    > .elementor-element-populated {
    padding: 0;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-5e011cd
    > .elementor-widget-container {
    padding: 0 0 12px;
  }

  .homepg .elementor-20
    .elementor-element.elementor-element-3fbecc2
    > .elementor-widget-container {
    padding: 0;
  }
}

@media (max-width: 544px) {
  .homepg h6,
  .homepg .entry-content h6 {
    font-size: 14px;
    font-size: 0.82352941176471rem;
  }

  .homepg h1,
  .homepg .entry-content h1 {
    font-size: 48px;
  }

  .homepg .elementor-button-wrapper .elementor-button.elementor-size-sm,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-xs,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-md,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-lg,
  .homepg .elementor-button-wrapper .elementor-button.elementor-size-xl,
  .homepg .elementor-button-wrapper .elementor-button {
    padding-top: 13px;
    padding-right: 21px;
    padding-bottom: 13px;
    padding-left: 21px;
  }
}
