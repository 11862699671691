.contact_detail .container {
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.contact_detail .column {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.contact_detail .contact_form {
    background: #a1c849;
    padding: 40px;
    border-radius: 10px;
}

.contact_detail .contact_form h4 {
    text-align: center;
    color: #fff;
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0px 0px 20px;
}

.contact_detail input {
    outline: none;
    box-shadow: none;
}

.contact_detail .form_group {
    margin-bottom: 15px;
}

.contact_detail .contact_form .form_sec .form-control {
    font-size: 16px;
    line-height: 21px;
    color: #000;
    height: auto;
    padding: 10px 10px;
    border-radius: 4px;
    border: 1px solid #fff;
    background: #fff;
}

.contact_detail .text-center {
    text-align: center;
}

.contact_detail .contact_form form.form_sec .contact_submit_btn .form_submit_btn {
    background: #759136;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    padding: 12px 30px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
}

.contact_detail .contact_address .contact_addr_col {
    margin-bottom: 30px;
    overflow: hidden;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact_detail .contact_address .contact_addr_col h4 {
    font-size: 21px;
    line-height: 24px;
    color: #759136;
    margin: 0px 0px 15px;
}

.contact_detail .contact_address .contact_addr_col p {
    font-size: 14px;
    line-height: 21px;
    color: #666;
}

.contact_detail p {
    margin: 0 0 10px;
}

.contact_detail .contact_address .contact_addr_col p a {
    color: #759136;
    text-decoration: none;
}

.contact_detail .mapbx>iframe {
    width: 100%;
    height: 247px;
    border: 5px solid #eee;
}

@media (min-width: 768px) {
    .contact_detail {
        padding: 40px 0px;
    }
}