.homepg .nicdark_section {
  width: 100%;
  float: unset !important;
}

.homepg .nicdark_position_relative {
  position: relative;
}

.homepg .nicdark_third_font {
  font-family: "Roboto", sans-serif;
}

.homepg .nicdark_font_size_180 {
  font-size: 180px;
  line-height: 180px;
}

.homepg .nicdark_font_weight_lighter {
  font-weight: lighter;
}

.homepg .nicdark_position_absolute {
  position: absolute;
}

.homepg .nicdark_section {
  width: 100%;
  float: left;
}

.homepg .nicdark_text_align_center {
  text-align: center;
}

.homepg .nicdark_margin_auto {
  margin: auto;
}

.homepg .nicdark_display_inline_block {
  display: inline-block;
}

.homepg .nicdark_text_align_left {
  text-align: left;
}

.homepg .nicdark_float_left {
  float: left;
}

.homepg .nicdark_section {
  width: 100%;
  float: left;
}

.homepg .nicdark_section {
  width: 100%;
  float: left;
}

.homepg .nicdark_height_90 {
  height: 90px;
}

.homepg .nicdark_section {
  width: 100%;
  float: left;
}

.homepg .nicdark_position_relative {
  position: relative;
}

.homepg .nicdark_text_align_center {
  text-align: center;
}

.homepg .nicdark_border_radius_100_percentage {
  border-radius: 100%;
}

.homepg .nicdark_position_absolute {
  position: absolute;
}

.homepg .nicdark_left_0 {
  left: 0;
}

.homepg .nicdark_custom_gradient_2 {
  background: -webkit-linear-gradient(
    left,
    rgba(159f, 198, 71, 1) 0%,
    rgba(159f, 198, 71, 1) 100%
  );
}

.homepg .nicdark_color_white {
  color: #fff;
}

.homepg .nicdark_font_size_20 {
  font-size: 20px;
  line-height: 20px;
}

.homepg .nicdark_line_height_40 {
  line-height: 40px;
}

.homepg .nicdark_padding_left_70 {
  padding-left: 70px;
}

.homepg .nicdark_box_sizing_border_box {
  box-sizing: border-box;
}

.homepg .nicdark_font_size_16 {
  font-size: 16px;
  line-height: 16px;
}

.homepg .nicdark_letter_spacing_2 {
  letter-spacing: 2px;
}

.homepg .nicdark_height_5 {
  height: 5px;
}

.homepg p {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: 300;
}

.homepg .nicdark_height_100 {
  height: 100px;
}

.homepg .nicdark_box_shadow_0_7_20_000 {
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
}

.homepg .nicdark_float_left {
  float: left;
}

.homepg .nicdark_text_align_center {
  text-align: center;
}

.homepg .nicdark_position_relative {
  position: relative;
}

.homepg .nicdark_transition_all_08_ease {
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

@media only screen and (min-width: 1199px) and (max-width: 1580px) {
  .homepg .nicdark_plugin_real_left {
    width: 277px !important;
    margin-left: 0px !important;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .homepg .nicdark_plugin_real_feature_first_space {
    height: 20px;
  }

  .homepg .nicdark_plugin_real_feature .nicdark_custom_gradient_2 {
    display: none;
  }

  .homepg .nicdark_plugin_real_feature .nicdark_padding_left_70 {
    padding-left: 0px;
  }

  .homepg .nicdark_plugin_real_feature_little_number {
    display: inline-block;
    background-color: rgb(159, 198, 71);
    background: -webkit-linear-gradient(
      left,
      rgba(159, 198, 71, 1) 0%,
      rgba(159f, 198, 71, 1) 100%
    );
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
    margin-right: 15px;
  }

  .homepg .nicdark_plugin_real_feature_space {
    height: 70px;
  }

  .homepg .nicdark_plugin_real {
    width: 630px !important;
  }
}

@media only screen and (min-width: 1199px) and (max-width: 1330px) {
  .homepg .nicdark_plugin_real_left {
  }
}
