.registerpg div {
  position: relative;
}

.registerpg button,
.registerpg a,
.registerpg span,
.registerpg div {
  transition: all 0.3s ease;
}

.registerpg .formify-form {
  overflow: hidden;
}

.registerpg .p-0 {
  padding: 0 !important;
}

.registerpg .formify-form__acenter {
  display: flex;
  align-items: center !important;
}

.registerpg .formify-bg-none {
  background: none !important;
}

.registerpg .formify-form__100vh {
  min-height: 100vh;
}

.registerpg .formify-form__layout {
  background: #fff;
  display: flex;
  overflow: hidden;
}

.registerpg .formify-form__layout--quiz {
  width: 100%;
  padding: 50px 0;
}

.registerpg .formify-bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.registerpg .formify-form__jcenter {
  display: flex;
  justify-content: center !important;
}

.registerpg .formify-form__ccolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registerpg .formify-form__quiz-banner {
  padding: 30px 50px;
  min-width: 26.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.registerpg .formify-form__quiz-banner--v5 {
  min-width: 41%;
}

.registerpg .formify-form__layout div {
  position: relative;
}

.registerpg .formify-form__quiz-banner-logo {
  max-width: 140px;
}

.registerpg .formify-form__quiz-banner-logo--v5 {
  position: absolute !important;
  top: 30px;
  left: 30px;
}

.registerpg .formify-form__layout div {
  position: relative;
}

.registerpg .container {
  max-width: 100% !important;
}

.registerpg img {
  height: auto;
  max-width: 100%;
}

.registerpg .formify-form__quiz-banner--content {
  max-width: 540px;
}

.registerpg .formify-form__quiz-banner--title {
  font-size: 60px;
  font-weight: 700;
  line-height: 108%;
  margin-bottom: 30px;
}

.registerpg p {
  color: #4b5563;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.registerpg .formify-form__quiz-banner--text {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.registerpg .formify-form__ccolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registerpg .formify-form__layout--quiz-main {
  min-width: 74.5%;
  padding: 50px;
}

.registerpg .formify-form__layout--quiz-main--v5 {
  min-width: 59%;
  background-color: #000000;
}

.registerpg .formify-form__inner--quiz {
  min-width: 750px;
  max-width: 750px;
}

.registerpg .list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.registerpg .formify-form__nav {
  display: inline-flex;
  flex-direction: row;
  /* gap: 125px; */
  justify-content: space-between;
  min-width: 100%;
  margin-bottom: 50px;
  flex-wrap: nowrap;
}

.registerpg .formify-form__nav:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #656565;
  margin-top: -0.5px;
}

.registerpg .list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.registerpg .formify-form__nav a.list-group-item {
  border: 1px solid #696969;
  background: #000;
  color: #9e9e9e;
  border-radius: 30px;
  font-size: 16px;
  min-width: 80px;
  min-height: 44px;
}

.registerpg .list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.registerpg .formify-form__nav a.list-group-item.active,
.registerpg .formify-form__nav a.list-group-item:hover {
  border-color: transparent;
  background: #a1c849;
  color: #fff;
}

.registerpg a {
  display: inline-block;
  cursor: pointer;
}

.registerpg .fade {
  transition: opacity 0.15s linear;
}

.registerpg .formify-forms__quiz-title--v5 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.registerpg .m-0 {
  margin: 0 !important;
}

.registerpg .formify-forms__quiz-text--v5 {
  color: #c6c9cf;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px !important;
  display: block;
}

.registerpg .formify-mg-top-40 {
  margin-top: 40px !important;
}

.registerpg .formify-mg-top-15 {
  margin-top: 15px !important;
}

.registerpg .formify-forms .form-group {
  margin-top: 20px;
  position: relative;
}

.registerpg .formify-forms .formify-forms__input {
  position: relative;
}

.registerpg body,
.registerpg button,
.registerpg input,
.registerpg select,
.registerpg optgroup,
.registerpg textarea {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #101010;
}

.registerpg input,
.registerpg textarea {
  font-weight: 400;
}

.registerpg input {
  line-height: normal;
}

.registerpg .formify-forms input,
.registerpg .formify-forms textarea,
.registerpg .formify-forms select,
.registerpg .formify-forms .nice-select {
  width: 100%;
  height: 52px;
  padding: 5px 15px 5px 15px;
  background: none !important;
  border: 1px solid #dcdfe6;
  color: #4b5563 !important;
  border-radius: 8px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  /* justify-content: ; */
}

.registerpg .formify-forms__input--quiz-label {
  border-radius: 5px;
  border: 2px solid #fff;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
}

.registerpg .formify-forms__input--role {
  min-height: 130px;
}

.registerpg .formify-forms__input--role {
  border-radius: 12px;
  border: 1px solid #fff;
  background: #000 !important;
}

.registerpg .formify-forms__input--role {
  align-items: inherit;
}

.registerpg .formify-forms label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #101010;
  margin-bottom: 5px;
}

.registerpg .formify-forms__role {
  display: flex;
  align-items: center;
  gap: 30px;
}

.registerpg .formify-forms__role img {
  max-width: 70px;
}

.registerpg .formify-forms__role-title {
  color: #fff;
  margin: 0;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 24px;
}

.registerpg p {
  color: #4b5563;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.registerpg .formify-forms__role-text {
  font-size: 18px;
  color: #fff;
}

.registerpg .formify-forms__quiz-check {
  min-width: 28px;
  min-height: 28px;
  border: 2px solid #fff;
  border-radius: 100%;
  cursor: pointer;
}

.registerpg .formify-forms__quiz-check--role {
  /* top: 15px; */
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;
  height: 100%;
  margin-block: auto;
}

.registerpg
  .formify-forms__quiz-form
  input:checked
  + .formify-forms__input--quiz-label
  .formify-forms__quiz-check {
  border-color: transparent;
}

.registerpg .formify-forms__quiz-check::before {
  content: "";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #a1c849;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 10px;
  background: #a1c849;
  border-radius: 100%;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.registerpg
  .formify-forms__quiz-form
  input:checked
  + .formify-forms__input--quiz-label
  .formify-forms__quiz-check::before {
  opacity: 1;
  visibility: visible;
}

.registerpg .formify-mg-top-15 {
  margin-top: 15px !important;
}

.registerpg .formify-forms .form-group {
  margin-top: 20px;
  position: relative;
}

.registerpg .formify-forms .formify-forms__input {
  position: relative;
}

.registerpg .formify-mg-top-40 {
  margin-top: 40px !important;
}

.registerpg .formify-forms .form-group {
  margin-top: 20px;
  position: relative;
}

.registerpg .formify-forms__quiz-form .formify-forms__button {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.registerpg .formify-btn {
  padding: 0 22px;
  text-align: center;
  height: 56px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3 linear;
  transition: all 0.3 linear;
  border: none;
  position: relative;
  background: #a1c849;
  font-size: 16px;
  color: #fff;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: initial;
  display: inline-flex;
  border-radius: 8px;
  font-weight: 600;
}

.registerpg .formify-forms__quiz-form .formify-btn {
  min-width: 228px;
  background: #a1c849;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
}

.registerpg .formify-forms--role-form .formify-btn {
  min-width: 160px;
  font-size: 18px;
  min-height: 58px;
}

.registerpg .formify-mg-top-10 {
  margin-top: 10px !important;
}

.registerpg .formify-form__layout div {
  position: relative;
}

.registerpg label {
  transition: all 0.3s ease;
}

.registerpg label {
  font-weight: 400;
  margin-bottom: 10px;
}

.registerpg .formify-forms label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #101010;
  margin-bottom: 5px;
}

.registerpg .formify-forms__quiz-form--v5 label {
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
}

.registerpg .formify-forms__quiz-form--v5 label span {
  color: #c10000;
}

.registerpg input[type="text"],
.registerpg input[type="email"],
.registerpg input[type="url"],
.registerpg input[type="password"],
.registerpg input[type="search"],
.registerpg input[type="number"],
.registerpg input[type="tel"],
.registerpg input[type="range"],
.registerpg input[type="date"],
.registerpg input[type="month"],
.registerpg input[type="week"],
.registerpg input[type="time"],
.registerpg input[type="datetime"],
.registerpg input[type="datetime-local"],
.registerpg input[type="color"],
.registerpg textarea {
  color: #9aa2b1;
  border-radius: 3px;
  padding: 0px;
  line-height: initial;
  width: 100%;
  height: 50px;
  background: #fafafa;
  border: 1px solid #e4f2ff;
  resize: none;
}

.registerpg .formify-forms input,
.registerpg .formify-forms textarea,
.registerpg .formify-forms select,
.registerpg .formify-forms .nice-select {
  width: 100%;
  height: 52px;
  padding: 5px 15px 5px 15px;
  background: none !important;
  border: 1px solid #dcdfe6;
  color: #4b5563 !important;
  border-radius: 8px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  /* justify-content: ; */
}

.registerpg .formify-forms__quiz-form--v5 input {
  height: 54px;
  border-radius: 6px;
  border: 1px solid #afafaf;
  font-size: 15px;
  color: #fff !important;
}

.registerpg .formify-forms__quiz-form--v5 input,
.registerpg .formify-forms__quiz-form--v5 textarea {
  color: #fff !important;
}

.registerpg .formify-forms textarea {
  min-height: 215px;
  padding-top: 15px;
}

.registerpg .formify-forms__quiz-form--v5 input,
.registerpg .formify-forms__quiz-form--v5 textarea {
  color: #fff !important;
}

.registerpg .formify-forms__quiz-form--v5 textarea {
  min-height: 115px;
}

.registerpg .formify-forms__spaceb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registerpg .formify-forms__checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.registerpg .formify-forms__checkbox label {
  font-weight: 400;
  /* font-size: ; */
  cursor: pointer;
}

.registerpg .formify-forms__quiz-form--v5 label {
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
}

.registerpg .formify-forms__quiz-form--v5 .formify-forms__checkbox label {
  color: #fefefe;
  font-size: 14px;
  font-weight: 400;
}

.registerpg .formify-forms__quiz-form--v5 .formify-forms__checkbox label a {
  font-weight: 400;
}

.registerpg .formify-forms__button {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.registerpg .formify-forms__quiz-form .formify-btn {
  min-width: 228px;
  background: #a1c849;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
}

.registerpg .formify-forms--role-form .formify-btn {
  min-width: 160px;
  font-size: 18px;
  min-height: 58px;
}

.registerpg .formify-forms--role-form .formify-btn.prev-step {
  background: #47581e;
}

.registerpg .formify-forms__quiz-form--v5-select label {
  height: 60px;
}

.registerpg .formify-forms__quiz-form--v5 .formify-forms__input--quiz-label {
  background: transparent !important;
}

.registerpg .formify-forms__quiz-value {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.registerpg .formify-forms__quiz-form--v5 .formify-forms__quiz-value {
  font-size: 16px;
  font-weight: 500;
}

.registerpg .formify-mg-top-30 {
  margin-top: 30px !important;
}

.registerpg .formify-forms__group-title {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.registerpg .formify-forms__input--cards label {
  height: 54px;
  border-radius: 6px;
  border: 1px solid #afafaf;
}

.registerpg .formify-forms__input--cards label .formify-forms__quiz-value {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #9aa1ac;
  font-size: 15px;
  font-weight: 600;
}

.registerpg .formify-forms__quiz-form--v5 .formify-forms__quiz-check {
  min-width: 20px;
  min-height: 20px;
}

.registerpg
  .formify-forms__quiz--v5
  .formify-forms__quiz-form
  input:checked
  + .formify-forms__input--quiz-label {
  border-color: #a1c849 !important;
}

.registerpg
  .formify-forms__quiz-form
  input:checked
  + .formify-forms__input--quiz-label
  .formify-forms__quiz-check {
  border-color: transparent;
}

@media (min-width: 1200px) {
  .registerpg .h2,
  .registerpg h2 {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1278px) and (max-width: 1600px) {
  .registerpg .formify-form__quiz-banner--v5 {
    min-width: 35%;
    max-width: 35%;
  }

  .registerpg .formify-form__layout--quiz-main--v5 {
    min-width: 65%;
    max-width: 65%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
  .registerpg .formify-form__acenter {
    align-items: start !important;
  }

  .registerpg .formify-form__layout {
    flex-direction: column;
  }

  .registerpg .formify-form__layout--quiz {
    padding: 100px 50px 0;
  }

  .registerpg .formify-form__jcenter {
    justify-content: initial !important;
  }

  .registerpg .formify-form__layout--quiz-main,
  .registerpg .formify-form__quiz-banner {
    min-width: 100%;
    max-width: 100%;
  }

  .registerpg .formify-form__quiz-banner--v5,
  .registerpg .formify-form__layout--quiz-main--v5 {
    min-width: 100%;
    max-width: 100%;
  }

  .registerpg .formify-form__quiz-banner--v5 {
    align-items: start;
  }

  .registerpg .formify-form__quiz-banner-logo--v5 {
    position: relative !important;
    left: 0;
    top: 0;
  }

  .registerpg .formify-form__quiz-banner--content {
    max-width: 100%;
  }

  .registerpg .formify-form__quiz-banner--title {
    font-size: 34px;
  }

  .registerpg .formify-form__quiz-banner--title {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .registerpg .formify-form__quiz-banner--text {
    font-size: 16px;
  }

  .registerpg .formify-form__layout--quiz-main--v5 {
    min-height: 100vh;
  }

  .registerpg .formify-form__inner--quiz {
    max-width: 100%;
    min-width: 100%;
  }

  .registerpg .formify-form__inner--quiz {
    min-width: 100%;
    max-width: 100%;
  }

  .registerpg .formify-form__nav {
    gap: 50px;
  }

  .registerpg .formify-form__nav {
    gap: 20px;
  }

  .registerpg .formify-form__nav {
    gap: 4px;
    margin-bottom: 20px;
  }

  .registerpg .formify-form__nav a.list-group-item {
    font-size: 14px;
    padding: 4px 12px;
    min-height: initial;
    min-width: initial;
  }

  .registerpg .formify-forms__quiz-title--v5 {
    font-size: 24px;
  }

  .registerpg .formify-forms__quiz-text--v5 {
    margin-top: 5px !important;
    font-size: 15px;
  }

  .registerpg .formify-forms__input--role {
    padding: 15px 10px;
  }

  .registerpg .formify-forms__role {
    gap: 10px;
  }

  .registerpg .formify-forms__role img {
    max-width: 38px;
  }

  .registerpg .formify-forms__role-title {
    font-size: 18px;
    margin: 0 0 5px;
  }

  .registerpg .formify-forms__role-text{
    font-size: 12px;
  }

  .registerpg .formify-forms__quiz-check--role {
    top: 10px;
    right: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .registerpg .formify-form__layout--quiz {
    padding: 100px 30px 0;
  }

  .registerpg .formify-form__layout--quiz-main,
  .registerpg .formify-form__quiz-banner {
    padding: 30px;
  }

  .registerpg .formify-forms__quiz-form.formify-mg-top-40,
  .registerpg .formify-forms__quiz-form .formify-mg-top-40 {
    margin-top: 20px !important;
  }

  .registerpg .formify-forms__input--quiz-label {
    height: 50px;
  }

  .registerpg .formify-forms__quiz-check {
    min-width: 22px;
    min-height: 22px;
  }

  .registerpg .formify-forms__quiz-form.formify-mg-top-40,
  .registerpg .formify-forms__quiz-form .formify-mg-top-40 {
    margin-top: 20px !important;
  }

  .registerpg .formify-forms__button {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .registerpg .formify-btn {
    font-size: 16px;
  }

  .registerpg .formify-form__nav{
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .registerpg .formify-form__nav:before {
    display: none;
  }
}
