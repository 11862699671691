/* Import Montserrat font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

/* Apply Montserrat font and fallback to sans-serif */
body,
html {
  font-family: 'Montserrat', sans-serif !important;
  margin: 0;
  /* margin-top: 45px; */
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

/* Basic styling for links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Basic styling for headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

/* Basic styling for container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
/* .DateRangePicker_picker__directionLeft{
  left:-25px !important
} */

/* Basic styling for main content area */
/* .main-content {
  padding: 20px;
} */

/* .home{
  background-color: black;
} */

/* @media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
} */
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1300px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1500px;
  }
}





.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #336d85;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top.hide {
  opacity: 0;
  pointer-events: none;
}

.form-group {
  margin-bottom: 1rem;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 500;
  font-size: 14px;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}