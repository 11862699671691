.service-container {
  padding: 20px;
}

.service-container .service-card {
  margin-bottom: 10px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.service-container .service-card a {
  color: inherit;
  text-decoration: none;
}

.service-container .service-img {
  width: 100%;
  max-width: 70px;
  margin: auto;
  display: block;
}

.service-container .card-title {
  text-align: center;
}

.service-container .card-body {
  padding: 5px;
}

@media (max-width: 576px) {
  .service-container .service-img {
    max-width: 50px;
  }

  .service-container .service-card {
    padding: 5px;
  }

  .service-container .card-title {
    font-size: small;
  }
}