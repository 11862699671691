/* .wrapper {
    position: relative;
    min-height: 100%;
} */

/*.wrapper*/ .main-header {
    position: sticky !important;
    top: 0px;
    left: 0px;
    z-index: 999;
}

.main-header  {
    background-color: #fff;
}

.main-header {
    border-bottom: 1px solid #dee2e6;
    z-index: 1034;
}

.main-header  {
    /* -ms-flex-flow: row nowrap; */
    flex-flow: row nowrap;
    /* -ms-flex-pack: start; */
    justify-content: flex-start;
}

.main-header  {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    /* -ms-flex-wrap: wrap; */
    flex-wrap: wrap;
    /* -ms-flex-align: center; */
    align-items: center;
    /* -ms-flex-pack: justify; */
    /* justify-content: space-between; */
    padding: .5rem .5rem;
}

.main-header  .navbar-nav {
    /* -ms-flex-direction: row; */
    flex-direction: row;
}

.main-header .navbar-nav {
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-direction: column; */
    /* flex-direction: column; */
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.main-header .icon {
    font-size: 13px;
    font-weight: 900;
    color: #a3b96f;
    /* Adjust icon size as needed */
}

.main-header .navbar-nav .nav-item {
    margin: 0;
}

.main-header  .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5);
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}

.main-header .nav-link {
    height: 2.5rem;
    position: relative;
}

.main-header .nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.main-header a {
    color: #37b1c2;
    text-decoration: none;
    background-color: transparent;
}

.main-header a:hover,
.main-header a {
    color: #000;
}

.main-header img {
    vertical-align: middle;
    border-style: none;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.main-header .ml-auto {
    margin-left: auto !important;
}

.main-header .navbar-nav .nav-item.quick_link {
    position: relative;
    margin: 0px 5px;
}

/* .main-header .navbar-nav .quick_link .nav-link {
    background-image: linear-gradient(to right, #a1c849, #76972c);
    border-radius: 4px;
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    display: block;
    height: auto;
    margin-top: 5px;
} */

.main-header .iconn{
    color: #a1c849;
}

.main-header .navbar-nav .support_link .dropdown-menu {
    width: 300px !important;
}

.main-header  .navbar-nav .dropdown-menu {
    position: absolute;
    padding: 0 10px;
}

/* .main-header .navbar-nav .quick_link .nav-link:hover,
.main-header .navbar-nav .quick_link .nav-link:focus,
.main-header .navbar-nav .quick_link.show .nav-link {
    color: #fff;
} */

.main-header .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    /* display: none; */
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);
}

.main-header .navbar-nav .support_link .dropdown-menu .dropdown-item {
    list-style-type: none;
    padding: 0px 8px;
}

.main-header .navbar-nav .support_link .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 7px;
    display: flex;
    padding: 10px 0;
}

.main-header .navbar-nav .support_link .dropdown-menu .cus_icon {
    width: 30px;
    display: inline-block;
}

.main-header .navbar-nav .support_link .dropdown-menu .cus_icon .icon {
    font-size: 24px;
    color: #a1c849;
}

.main-header .navbar-nav .support_link .dropdown-menu .cus_txt {
    width: calc(100% - 40px);
    margin-left: 6px;
    display: inline-block;
    word-wrap: break-word;
    vertical-align: top;
}

.main-header .navbar-nav .support_link .dropdown-menu .cus_txt span.title {
    font-size: 16px;
    line-height: 21px;
    color: #000;
    display: block;
    margin: 0px 0px 5px;
}

.main-header .navbar-nav .support_link .dropdown-menu .cus_txt span.sub_txt {
    font-size: 13px;
    line-height: 16px;
    color: #808080;
    display: block;
    margin: 0px 0px 3px;
}

.main-header .navbar-nav .quick_link .dropdown-menu .dropdown-item {
    font-size: 14px;
    line-height: 21px;
    padding: 5px 10px;
    color: #000;
    border-bottom: 1px solid #a1c849;
    text-align: left !important;
}

.main-header .navbar-nav .quick_link .dropdown-item .icon {
    font-size: 12px;
    margin-right: 3px;
}

.main-header .navbar-nav .quick_link .dropdown-menu .dropdown-item:last-child {
    margin-bottom: 0px;
}




.main-header .agent_nav {
    /* width: 50% !important; */
    margin-left: 10px;
}

/* .main-header .agent_nav>.agentnav_item {
    position: relative;
    width: 20%;
} */

.main-header .agent_nav>.agentnav_item,
.main-header .agent_nav>.agentnav_item a {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #82a534;
    text-align: center;
    /* padding: 5px 8px; */
}

.main-header .agent_nav>.agentnav_item .mail_link {
    display: inline;
    font-size: 14px;
    line-height: 18px;
    color: black;
    text-align: center;
    /* padding: 5px 8px; */
}

.main-header .agent_nav .agentnav_item a:hover {
    color: #82a534;
}

.main-header .agent_nav .icon {
    font-size: 15px;
    width: 100%;
    display: block;
}

.main-header .agent_nav .agentnav_item span {
    display: inline-block;
}

/* .main-header .agent_nav .agentnav_item a span.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
} */

.main-header .agent_nav .agentnav_item:hover .dropdown-menu {
    display: block;
}

.main-header .agent_nav .agentnav_item .dropdown-menu {
    margin: 0px;
    padding: 0px;
}

.main-header .agent_nav .agentnav_item .dropdown-menu .dropdown-item {
    display: block;
    margin: 0px;
}

.main-header .agent_nav .agentnav_item .dropdown-menu .dropdown-item {
    text-align: left;
    padding: 8px 10px;
    border-bottom: 1px dashed rgba(227, 17, 26, .18);
    color: #000;
}

.main-header .dropdown-item:hover {
    color: #a1c849 !important;
}

.main-header .dropdown-item:active {
    color: #fff !important;
    background-color: #a1c849 !important;
}



@media (min-width: 998px) {
    /* .sidebar-mini.sidebar-collapse .main-header { */
        /* margin-left: 4.6rem !important; */
    /* } */

    /* body:not(.sidebar-mini-md)   .main-header {
        width: calc(100% - 250px);
        height: 60px;
    } */
}

@media (min-width:768px) {
    /* body:not(.sidebar-mini-md) */ .main-header {
        transition: margin-left .3s ease-in-out;
        /* margin-left: 250px; */
    }

    /* .agent_mob_logo {
        display: none;
    } */

    .main-header .nav_support_menu {
        order: 2 !important;
    }

    /* .main-header .navbar-nav .nav-item.prf_link {
        order: 2;
    } */

    .main-header .navbar-nav .nav-item.quick_link.prf_link .dropdown-menu {
        left: auto;
        right: 0px;
    }
    .main-header .menu-icon{
        margin: 0 25px 0 10px;
    }

}

@media (max-width: 480px) {
    .main-header .agent_mob_logo {
        width: 120px;
    }
}

@media (max-width:767px) {
    .main-header .agent_mob_logo {
        display: inline-block;
        margin-left: auto;
    }

    .main-header .agent_mob_logo img {
        width: 100%;
    }

    .main-header .nav_support_menu {
        display: inline-block;
        float: right;
    }

    /* body:not(.sidebar-mini-md) */ .main-header {
        width: 100%;
        height: 75px;
        display: flex;
        z-index: 1050;
    }

    .main-header .nav_toggle {
        display: inline-block;
    }

    .main-header .navbar-nav .nav-item.support_link {
        display: none;
    }

    .main-header .navbar-nav .nav-item.quick_link {
        margin: 0px;
        display: block;
    }

    .main-header .navbar-nav .quick_link .nav-link {
        padding: 5px 8px;
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
    }

    .main-header .nav_support_menu .dropdown-menu {
        left: auto;
        right: 0px;
    }
    
    .main-header .nav_support_menu #balance-dropdown{
        margin-bottom: 5px !important;
    }
    
}

@media (max-width: 998px) {
    /* body:not(.sidebar-mini-md) */ .main-header {
        margin-left: 0;
    }
}

.main-header .agent_nav .nav-link{
    padding: 4px !important;
}

.icon{
    color: #a1c849;
}