.contact_head{
    padding: 25px 0;
}

.contact_head .container {
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.contact_head .text-center {
    text-align: center;
}

.contact_head .column {
    position: relative;
    min-height: 1px;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding: 15px;
}

.contact_head .contact_boxcol {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

/* .contact_head .contactBoxes .contact_icon{
    display: block;
} */

.contact_head .contactBoxes .contact_icon img {
    margin: auto;
}

.contact_head .img_responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.contact_head img {
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

.contact_head .contactBoxes .contact_info {
    display: block;
    padding-top: 10px;
}

.contact_head .contact_info {
    border-top: 1px solid #e0dede;
    padding: 5px;
    margin: 10px;
    overflow: auto;
    scrollbar-width:none;
    /* height: 100px; */
}

.contact_head .contactBoxes .contact_info strong {
    font-weight: 400;
    text-transform: capitalize;
    color: #5b5b5b;
}

.contact_head .contactBoxes .contact_info span{
    color: #333;
    display: block;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 4px;
}

.contact_head .contactBoxes .contact_info a {
    color: #5b5b5b;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-decoration: none;
}

.contact_head .contactBoxes .contact_info a:hover{
    color: #a1c849;
}

@media (min-width:992px) {
    .contact_head .contactBoxes .contact_info strong {
        font-size: 20px;
        line-height: 24px;
    }

    .contact_head .contactBoxes .contact_info span{
        font-size: 20px;
        line-height: 24px;
    }
}