.footer-width-fixer {
  width: 100%;
}

.elementor-element {
  --widgets-spacing: 20px;
}

.elementor-1047 .elementor-element.elementor-element-94e2fed {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 144px 40px;
}

.elementor-1047
  .elementor-element.elementor-element-94e2fed:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1047
  .elementor-element.elementor-element-94e2fed
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-astglobalcolor7);
  background-image: url(https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/06/footer-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor .elementor-background-overlay,
.elementor .elementor-background-slideshow {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.elementor-1047
  .elementor-element.elementor-element-94e2fed
  > .elementor-background-overlay {
  background-color: black;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-section .elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1200px;
}

.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
}

.elementor-element {
  --widgets-spacing: 20px;
}

@media (min-width: 768px) {
  .elementor-column.elementor-col-100,
  .elementor-column[data-col="100"] {
    width: 100%;
  }
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.elementor-widget-wrap > .elementor-element {
  width: 100%;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-1047 .elementor-element.elementor-element-a27d3a6 {
  text-align: center;
}

.elementor-element .elementor-widget-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
}

.elementor-1047
  .elementor-element.elementor-element-a27d3a6
  > .elementor-widget-container {
  margin: 0 0 20px;
  padding: 0;
}

.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.elementor-widget-heading .elementor-heading-title {
  margin: 0;
}

.elementor-widget-heading h6.elementor-heading-title {
  line-height: 1.25em;
}

.elementor-1047
  .elementor-element.elementor-element-a27d3a6
  .elementor-heading-title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.elementor-1047 .elementor-element.elementor-element-210cfd0 {
  text-align: center;
}

.elementor-element .elementor-widget-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
}

.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.elementor-widget-heading .elementor-heading-title {
  margin: 0;
}

.elementor-widget-heading h2.elementor-heading-title {
  line-height: 1.3em;
}

.elementor-1047
  .elementor-element.elementor-element-210cfd0
  .elementor-heading-title {
  color: #fff;
}

.elementor-1047 .elementor-element.elementor-element-0adb771 {
  text-align: center;
}

.elementor-1047
  .elementor-element.elementor-element-0adb771
  > .elementor-widget-container {
  margin: 20px 0 0;
}

.elementor-1047
  .elementor-element.elementor-element-0adb771
  .elementor-heading-title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.elementor-1047 .elementor-element.elementor-element-27930c1 {
  --e-icon-list-icon-size: 40px;
  --icon-vertical-offset: 0px;
}

.elementor-1047
  .elementor-element.elementor-element-27930c1
  > .elementor-widget-container {
  padding: 0 0 24px;
}

.elementor-widget .elementor-icon-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#left-area ul.elementor-icon-list-items,
.elementor-edit-area .elementor-element ul.elementor-icon-list-items,
.elementor .elementor-element ul.elementor-icon-list-items {
  padding: 0;
}

.elementor-widget .elementor-icon-list-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.elementor-widget .elementor-icon-list-item,
.elementor-widget .elementor-icon-list-item a {
  display: flex;
  font-size: inherit;
  align-items: var(--icon-vertical-align, center);
}

.elementor-widget.elementor-align-center .elementor-icon-list-item,
.elementor-widget.elementor-align-center .elementor-icon-list-item a {
  justify-content: center;
}

.elementor a {
  box-shadow: none;
  text-decoration: none;
}

.elementor-widget .elementor-icon-list-item,
.elementor-widget .elementor-icon-list-item a {
  display: flex;
  font-size: inherit;
  align-items: var(--icon-vertical-align, center);
}

/* .elementor-widget.elementor-list-item-link-full_width a {
  width: 100%;
} */

.elementor-1047
  .elementor-element.elementor-element-27930c1
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-1047
  .elementor-element.elementor-element-27930c1
  .elementor-icon-list-item
  > a {
  /* font-family: poppins, Sans-serif; */
  font-size: 24px;
  font-weight: 500;
  line-height: 1em;
  color: white;
  /* padding: 10px; */
}

.elementor-widget .elementor-icon-list-icon {
  display: flex;
  position: relative;
  top: var(--icon-vertical-offset, initial);
}

.elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon {
  text-align: var(--e-icon-list-icon-align);
}

.elementor-1047
  .elementor-element.elementor-element-27930c1
  .elementor-icon-list-icon {
  padding-right: 16px;
}

.elementor-widget .elementor-icon-list-icon i {
    width: 1.25em;
    font-size: var(--e-icon-list-icon-size);
}

.elementor-1047 .elementor-element.elementor-element-27930c1 .elementor-icon-list-icon i {
    color: #fff;
    transition: color .3s;
}

.elementor-widget .elementor-icon-list-icon+.elementor-icon-list-text {
    align-self: center;
    padding-inline-start: 5px;
}

.elementor-1047 .elementor-element.elementor-element-27930c1 .elementor-icon-list-text {
    color: #fff;
    transition: color .3s;
}