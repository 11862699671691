.homepg .nicdark_height_80 {
  height: 80px;
}

.homepg .nicdark_container {
  max-width: 1200px;
  margin: auto;
  padding: 0px;
}

/* .homepg .grid_12 {
  width: 100%;
} */

.homepg .nicdark_text_align_center {
  text-align: center;
}

.homepg .nicdark_position_relative {
  position: relative;
}

.homepg .nicdark_font_size_180 {
  font-size: 180px;
  line-height: 180px;
}

.homepg .nicdark_font_weight_lighter {
  font-weight: lighter;
}

.homepg .nicdark_position_absolute {
  position: absolute;
}

.homepg .nicdark_left_0 {
  left: 0;
}

.homepg .nicdark_top_0 {
  top: 0;
}

.homepg .nicdark_font_size_50 {
  font-size: 50px;
  line-height: 50px;
}

.homepg .nicdark_section {
  width: 100%;
  float: left;
}

.homepg .nicdark_height_40 {
  height: 40px;
}

/* .homepg .grid_6 {
  width: 50%;
} */

.homepg .grid_1,
.homepg .grid_2,
.homepg .grid_3,
.homepg .grid_4,
.homepg .grid_5,
.homepg .grid_6,
.homepg .grid_7,
.homepg .grid_8,
.homepg .grid_9,
.homepg .grid_10,
.homepg .grid_11,
.homepg .grid_12 {
  padding: 15px;
  float: left;
  display: inline;
  box-sizing: border-box;
}

.homepg .nicdark_height_5 {
  height: 5px;
}

.homepg .nicdark_display_table {
  display: table;
}

.homepg .nicdark_display_table_cell {
  display: table-cell;
}

.homepg .nicdark_vertical_align_middle {
  vertical-align: middle;
}

.homepg .nicdark_padding_right_10 {
  padding-right: 10px;
}

.homepg .nicdark_vertical_align_middle {
  vertical-align: middle;
}

.homepg .nicdark_text_transform_uppercase {
  text-transform: uppercase;
}

.homepg .nicdark_color_grey {
  color: #7e7e7e;
}

.homepg .nicdark_font_size_16 {
  font-size: 16px;
  line-height: 16px;
}

.homepg .nicdark_letter_spacing_2 {
  letter-spacing: 2px;
}

.homepg .nicdark_padding_left_10 {
  padding-left: 10px;
}

.homepg .nicdark_height_20 {
  height: 20px;
}

.homepg .nicdark_height_30 {
  height: 30px;
}

.homepg .nicdark_text_align_center {
  text-align: center;
}

.homepg .nicdark_padding_40 {
  padding: 40px;
}

.homepg .nicdark_box_sizing_border_box {
  box-sizing: border-box;
}

/* .homepg .nicdark_width_25_percentage {
  width: 25%;
} */

.homepg .nicdark_float_left {
  float: left;
}

.homepg .nicdark_height_15 {
  height: 15px;
}

.homepg .nicdark_color_white {
  color: #fff;
}

.homepg .nicdark_font_size_12 {
  font-size: 12px;
  line-height: 12px;
}

.homepg .nicdark_letter_spacing_2 {
  letter-spacing: 2px;
}

.homepg .nicdark_line_height_22 {
  line-height: 22px;
}

.homepg .nicdark_height_40 {
  height: 40px;
}

/* .homepg .grid_4 {
  width: 33.33%;
} */

.homepg .nicdark_position_relative {
  position: relative;
}

.homepg .nicdark_left_0 {
  left: 0;
}

.homepg .nicdark_position_absolute {
  position: absolute;
}

.homepg .nicdark_padding_left_100 {
  padding-left: 100px;
}

.homepg .nicdark_box_sizing_border_box {
  box-sizing: border-box;
}

.homepg h3 {
  font-size: 20px;
  line-height: 20px;
}

.homepg .nicdark_height_15 {
  height: 15px;
}

.pluginpg .nicdark_section{
  float: left !important;
}

.pluginpg.nicdark_section{
  float: left !important;
}

.homepg .nicdark_position_absolute svg{
  color: white;
  top: 29;
  left: 20;
  width: 100%;
  text-align: center;
  height: 60%;
}
