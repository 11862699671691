/* Header.css */
.custom-navbar {
    font-family: 'Montserrat', sans-serif !important;
    padding: 0px 0px;
    background-color: #fff;
    color: #333333;

    position: sticky !important;
    background: #fff;
    transition: opacity 0.5s;
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9;
}

.custom-navbar .brand-image {
    margin: 5px;
    height: 45px;
}

.custom-navbar .navbar-nav .nav-link {
    padding: 10px 5px;
    font-size: 12px;
    text-decoration: none;
    margin: 0px 4px !important;
    /* margin-right: 10px; */
}

.custom-navbar .navcontainer {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    /* column-gap: 20px; */
    column-gap: 10px;
}

/* .nav-link {
    display: flex;
    align-items: center;
  } */
.custom-navbar .icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    /* Adjust margin as needed */
}

.custom-navbar .icon {
    font-size: 13px;
    font-weight: 900;
    color: #a3b96f;
    /* Adjust icon size as needed */
}

.custom-navbar .text {
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}


.custom-navbar button {
    display: flex;
    text-align: center;
    color: #fff;
    font-size: 12px;
    /* font-weight: normal; */
    font-weight: normal;
    padding: 8px 15px;
    border-radius: 4px;
    background: #1f5e79;
    border: none;
    height: 35px;
}

.custom-navbar button:hover {
    background: #154c62 !important;
}

.custom-navbar button:active {
    background: #154c62 !important;
}

.custom-navbar button:focus-visible {
    background: #154c62 !important;
}

.custom-navbar .dropdown-item{
    text-decoration: none;
}

.custom-navbar .dropdown .btn{
    background: #1f5e79 !important;
}

.custom-navbar .dropdown .btn:hover{
    background: #154c62 !important;
}

.custom-navbar .dropdown-item:active {
    background-color: #154c62 !important;
}

.custom-navbar .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: flex; */
    flex-wrap: nowrap;
    column-gap: 5px;
    text-decoration: none !important;

}

.custom-navbar .buttons-container .btn {
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: auto;
}

.custom-navbar .buttons-container .btn-icon {
    font-size: 13px;
    margin-right: 3px;
    font-weight: 900;
}

@media (max-width: 768px) {
    .custom-navbar .buttons-container {
        flex-direction: row;
        /* Ensure buttons are side by side when collapsed */
        justify-content: flex-end;
        /* Align buttons to the right when collapsed */
    }

    .custom-navbar .buttons-container .btn {
        margin-right: 0;
        /* Remove margin between buttons when collapsed */
        margin-top: 5px;
        margin-bottom: 5px;
        /* Add some space between buttons when collapsed */
        width: 100%;
        /* Make buttons take full width when collapsed */
    }

    .custom-navbar .navbar-toggler {
        display: flex;
        align-items: center;
    }

    /* .menu-icon{
        color: #fff !important;
    } */
    /* .navbar-toggler-icon{
        background-color: #fff !important;
    } */

    .custom-navbar .brand-image {
        padding: 7px 0px;
        width: 90px;
    }

    .custom-navbar .hidden {
        display: none;
    }

    /* .navcontainer{
        padding: 0;
    } */

    .navbar-brand{
        margin-right: 0;
    }
}

.custom-navbar .nav-link {
    position: relative;
    /* Set position to relative for absolute positioning */
}

.custom-navbar .nav-link::before {
    content: "";
    /* Add pseudo-element for hover effect */
    position: absolute;
    /* Set position to absolute */
    bottom: -2px;
    /* Position the pseudo-element at the bottom */
    left: 0;
    /* Align the pseudo-element to the left */
    width: 100%;
    /* Set width to 100% */
    height: 2px;
    /* Set height of the line */
    background-color: rgba(193, 99, 99, 0);
    /* Set initial background color */
    transition: background-color 0.3s ease;
    /* Add transition for smooth animation */
}

.custom-navbar .nav-link:hover::before {
    background-color: #1f5e79;
    /* Change background color on hover */
}

.custom-navbar .dropdown-toggle::after {
    display: inline-block;
}

.custom-navbar .dropdown-menu.show .dropdown-item .icon-container{
    display: inline;
    margin-right: 10px;
}

.custom-navbar .dropdown-menu.show .dropdown-item .text{
    display: inline;
}