.inner_box .py-50{
    padding: 50px 0;
}

.inner_box .container{
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.inner_box .col-md-6{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.inner_box .about_content h4{
    color: #759136;
    font-weight: 600;
}

.inner_box .about_content p{
    color: #585858;
    margin: 0px 0px 10px;
    font-weight: 400;
}

.inner_box .about_img{
    padding: 2px;
}

.inner_box .about_img img{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 10px;
}

.inner_box .img_responsive{
    display: block;
    max-width: 100%;
    height: auto;
}

.inner_box img{
    vertical-align: middle;
    border: 0;
}

.inner_box .bg-light{
    background: #fafafa;
}

.inner_box .mt25{
    margin-top: 25px;
}

.inner_box .mr-5{
    margin-right: 5px;
}

.inner_box ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.inner_box .listsr li{
    position: relative;
    padding: 6px 0px;
}

.inner_box ul li{
    transition: all .3s;
}

@media (min-width:1200px) {
    .inner_box .align-items-center-lg{
        align-items: center;
    }

    .inner_box .d-flex-lg{
        display: flex;
    }
}

@media (min-width:992px) {
    .inner_box .col-md-6{
        width: 50%;
        float: left;
    }
}

@media (min-width:768px) {
    .inner_box .about_content h4{
        font-size: 28px;
        line-height: 36px;
        margin: 0px 0px 15px;
    }

    .inner_box .about_content p{
        font-size: 16px;
        line-height: 28px;
    }
}