.inner_custom_page{
    padding: 80px 0;
}

.inner_custom_page .container{
    margin-left: auto;
    margin-right: auto;
}

.inner_custom_page .inner_box{
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(144, 29, 120, 0.2);
    padding: 30px 15px;
    border: 1px solid #a1c849;
}

.inner_custom_page .text-center{
    text-align: center;
}

.inner_custom_page .col-md-6{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.inner_custom_page .inner_box h3{
    font-size: 30px;
    line-height: 34px;
    color: #a1c849;
    margin: 10px 0px 15px;
    font-weight: 600;
}

.inner_custom_page .inner_box p{
    font-size: 14px;
    line-height: 21px;
    color: #000;
    margin-bottom: 20px;
    font-weight: normal;
}

.inner_custom_page p{
    margin: 0 0 10px;
}

.inner_custom_page .inner_box .round_img{
    border-radius: 50%;
    border: 2px solid #a1c849;
}

.inner_custom_page .inner_box .custom_pg_img{
    width: 200px;
    height: 200px;
    margin: 0px auto 20px;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
}

.inner_custom_page .inner_box .custom_pg_img img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.inner_custom_page .img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
}

.inner_custom_page img{
    max-height: 100%;
    vertical-align: middle;
}