.career_page section{
    padding: 40px 0;
}

.career_page .container{
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.career_page .flex-wrap{
    flex-wrap: wrap;
}

.career_page .d-flex{
    display: flex;
}

.career_page section h3{
    line-height: 40px;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 500;
}

.career_page .column6{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.career_page section p{
    font-size: 16px;
    line-height: 28px;
    color: #3d3d3d;
    margin: 0 0 10px;
}

.career_page .shadow_img{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 10px;
}

.career_page img{
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    border: 0;
}





.career_page .bg-light{
    background: #fafafa;
}

.career_page .text_center{
    text-align: center;
}

.career_page .career_card{
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    min-height: 350px;
}

.career_page .career_card h5{
    font-size: 20px;
    margin: 15px 0;
    font-weight: 600;
}

.career_page .career_form{
    width: 70%;
    margin: 40px auto 0;
    padding: 40px 35px;
    background: #cccccc1a;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.career_page .career_form h4{
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
    color: #1f5e79;
}

.career_page .form-group{
    margin-bottom: 15px;
}

.career_page .career_form label{
    font-weight: 500;
    font-size: 16px;
}

.career_page .career_form .form-control:not(textarea){
    height: 45px;
}

.career_page .career_form .form-control{
    border-radius: 6px;
    border-color: #e8e8e8;
}

.career_page input, .career_page .form-control{
    outline: none !important;
    box-shadow: none !important;
}

.career_page .career_form .btn{
    padding: 10px 30px;
    font-size: 18px;
    text-transform: uppercase;
}

.career_page .btn.btn-success {
    background: #a3b96f;
    border-color: #a3b96f;
}

@media (min-width:1200px){
    .career_page .align-items-center-lg{
        align-items: center;
    }
}

@media (max-width:768px){
    .career_page .career_form{
        width: 100%;
        margin: 40px auto 0;
        padding: 40px 15px;
        background: #cccccc1a;
        border-radius: 20px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }
}