.dashboard_home .wallet-card,
.booking-graph-container {
    /* margin-top: 20px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 0;
    margin-bottom: 5px;
}

.booking-graph-container .line_graph_wrapper{
    height: 400px
}



.dashboard_home .wallet-icon {
    margin-right: 5px;
}

.dashboard_home .card-header {
    background-color: #ffffff;
}




.dashboard_home .notifications-container {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    position: relative;
    margin-bottom: 20px;
}

.dashboard_home .notifications-heading {
    background-color: #a1c849;
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.dashboard_home .notification-card {
    padding: 0px;
    border: none !important;
    border-bottom: 1px solid #dee2e6;
}

.dashboard_home .notification-card .card-title {
    font-size: 14px;
}

.dashboard_home .notification-card .card-body {
    padding: 5px;
}

.dashboard_home .notification-divider {
    margin: 0;
}

.dashboard_home .notifications-container::-webkit-scrollbar {
    width: 8px;
}

.dashboard_home .notifications-container::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 4px;
}

.dashboard_home .notifications-container::-webkit-scrollbar-track {
    background-color: #ffffff;
}


.dashboard_home .amazing-container {
    margin-top: 20px;
}

.dashboard_home .latest-bookings {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    position: relative;
    margin-left: 20px;
}

.dashboard_home .card-header {
    background-color: #acc66f;
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.dashboard_home .latest-bookings .card-body {
    padding: 0;
}

.copyright_container{
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0px;
}




.box-bg {
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.3);
}

.info-box {
    display: block;
    height: 80px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    border-radius: 2px;
}

.info-box-icon {
    border-radius: 2px 0 0 2px;
    display: block;
    float: left;
    height: 80px;
    width: 50px;
    text-align: center;
    font-size: 30px;
    line-height: 80px;
    background: rgba(0, 0, 0, .2);
    color: #fff !important;
}

.bg-red {
    background-color: #dd4b39 !important;
    color: #fff;
}

.bg-aqua {
    background-color: #00c0ef !important;
    color: #fff;
}

.bg-yellow {
    background-color: #f39c12 !important;
    color: #fff;
}

.bg-green {
    background-color: #22af47 !important;
    color: #fff;
}

.info-box-text {
    text-transform: uppercase;
}

.info-box-text, .progress-description {
    display: block;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 5px 0;
}

.info-box-number {
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    padding: 0 5px;
}

.info-box-more {
    display: flex;
    font-size: 12px;
    padding-left: 5px;
}



.dashboxcolor2 {
    background: #f0b174;
}

.dashboxcolor3 {
    background: #b2d280;
}

.dashboxcolor4 {
    background: #3cc9ca;
}

.dashboardbox {
    width: 100%;
    float: left;
    position: relative;
    border-radius: 5px;
    min-height: 150px;
    margin: 0;
}

.boxheadcolor2 {
    background: #e09348;
}

.boxheadcolor3 {
    background: #9abf61;
}

.boxheadcolor4 {
    background: #27b5b6;
}

.dashboardbox .boxheading {
    font-size: 14px;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 6px 6px 0 0;
    margin: 0 auto;
    font-weight: 400;
    font-style: normal;
}

.dashinnerbox {
    width: 100%;
    padding: 5px;
    float: left;
}

ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.dashinnerbox ul li {
    font-weight: 300;
    color: #fff;
    font-size: 12px;
    float: left;
    width: 100%;
}

.dashinnerbox ul li label {
    font-weight: 100;
    color: #fff;
    font-size: 12px;
    float: left;
    min-width: 114px;
    margin: 0;
    margin-right: 5px;
    text-wrap: nowrap;
}