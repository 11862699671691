.main-sidebar {
    position: fixed;
    /* top: 63px; */
    left: -250px;
    width: 250px;
    height: 90%;
    background-color: #fff;
    transition: all 0.3s ease;
    overflow-y: auto;
    scrollbar-width: none;
    padding: 0 .5rem;
    z-index: 999999998;
    padding-bottom: 40px;
}

.main-sidebar.active {
    left: 0;
}

.main-sidebar .sideicon{
    font-size: 20px;
    font-weight: 900;
}

.main-sidebar .nav-link {
    color: black !important;
}

.main-sidebar .submenu {
    padding-left: 20px;
}

@media (max-width: 768px) {
    .main-sidebar {
        font-size: small;
        width: 200px;
    }

    .main-sidebar .submenu {
        font-size: 11px;
    }
}

@media (min-width:992px) {
    .main-sidebar, .nav_toggle{
        display: none !important;
    }
}

@media (max-width:992px){
    .main-header{
        justify-content: space-between !important;
    }
}