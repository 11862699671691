/* ul.bullets li {
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: baseline;
    gap: 8px;
} */

.single_pack_banner {
    padding: 30px 0 20px;
    /* margin-top: 54px; */
    background: #1f5e79;
}

.details_image {
    overflow: hidden;
    position: relative;
    height: 300px;
    display: none;
}

.pack_details .details_image>img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
}

.details_image>img {
    max-height: initial;
}

.pack_details .pack_banner_title {
    position: relative;
    z-index: 2;
}

.pack_details .pack_banner_title h1 {
    color: #ffffff;
    font-size: 20px;
    line-height: 28px;
    margin: 0px 0px 6px;
    font-weight: 600;
}

.pack_banner_title span.count_days {
    background: #a1c84824;
    color: #a1c848;
    display: inline-block;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 21px;
    border: 0px;
    margin-bottom: 10px;
    font-weight: 600;
    border-radius: 4px;
}

.pack_details .pack_banner_title p {
    color: #e9e9e9;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
}

.packageDetailView {
    background: #1f5e790d;
}

.details_main .secondary_nav.stickybar {
    position: sticky;
    top: 60px;
    z-index: 9;
}

.secondary_nav {
    background: #f5f5f5;
    border-bottom: 1px solid #ededed;
    padding: 10px 0;
}

.secondary_nav .container {
    display: flex;
    align-items: center;
}

.details_main .secondary_nav .nav-wrapper {
    display: flex;
    /* width: calc(100% - 230px); */
    margin-right: 20px;
    overflow-x: auto;
}

.secondary_nav .nav-wrapper {
    list-style-type: none;
    margin-bottom: 0;
}

.secondary_nav .nav-wrapper .nav-item {
    display: inline-block;
    margin-right: 10px;
    text-wrap: nowrap;
}

a.active {
    background: #a1c848;
    color: #fff;
}

.secondary_nav .nav-wrapper .nav-item a {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    padding: 6px 10px;
    border-radius: 20px;
}

.download_icon {
    text-wrap: nowrap;
}

.details_main .pack_details .download_btn {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
}

.pack_details .download_btn .dropdown {
    display: inline-block;
}

.pack_details .download_btn button.sharebtn {
    background: #89ad3e;
    outline: none;
    border: 0px;
    color: #fff;
    padding: 3px 8px;
    border-radius: 4px;
    height: auto;
    font-size: 16px;
    line-height: 28px;
}

/* .details_main .pack_details .download_btn .dropdown .dropdown-menu {
    display: none;
} */

.pack_details .download_btn .dropdown-menu {
    top: 30px !important;
    transform: initial !important;
    position: absolute !important;
    right: 0px !important;
    left: auto !important;
}

.pack_details .download_btn .dropdown-menu .dropdown-item {
    margin-right: 0px;
    margin-bottom: 10px;
    display: block;
    padding: 0px 10px;
}

.pack_details .download_btn .dropdown-menu .dropdown-item a {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #89ad3e;
    padding: 0px;
    font-weight: 600;
}

.pack_details .download_btn .dropdown-menu .dropdown-item a img {
    margin-right: 5px;
}

.padd_30_25 {
    padding-top: 30px;
    padding-bottom: 25px;
}

.sbs--border {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #d1d5da;
    border-radius: 0.5rem;
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.sbs--border li {
    flex: 1;
}

.sbs>li {
    cursor: pointer;
}

.sbs--border li .step {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    position: relative;
}

.sbs--border li:not(:last-child) .step::before,
.sbs--border li:not(:last-child) .step::after {
    content: "";
    height: 35px;
    width: 1px;
    background-color: #d1d5da;
    position: absolute;
    right: 0;
    top: 50%;
}

.sbs--border li .step::before {
    transform-origin: center bottom;
    transform: translateY(-100%) rotate(-25deg);
}

.sbs--border li .step::after {
    transform-origin: center top;
    transform: rotate(25deg);
}

.sbs--border li.active .step .indicator {
    color: #257f3e;
    border-color: #257f3e;
}

.sbs--border li .step .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    border: 2px solid #d1d5da;
    color: #8a94a1;
}

.sbs--border li.active .step .description {
    color: #257f3e;
}

.sbs--border li .step .description {
    font-weight: bold;
    font-size: 13px;
    color: #8a94a1;
}




.pack_disflex,
.flex_col {
    display: flex;
}

.gallery_section #pack_carousel .carousel-control {
    background-image: none;
    opacity: 1;
    top: 40%;
    width: 40px;
    height: auto;
    bottom: auto;
}

ul.bullets {
    line-height: 1.8;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.bullets li {
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: baseline;
    gap: 8px;
}

.trip_inclusion ul {
    margin: 20px 0 !important;
}

.trip_inclusion ul,
.pack_inclusion ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.trip_inclusion ul li {
    display: inline-flex;
    align-items: center;
}

.trip_inclusion ul li,
.pack_inclusion ul li {
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #666;
}

.trip_inclusion ul li img {
    margin-right: 5px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.pack_details .common_section {
    margin-bottom: 30px;
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.common_section h2 {
    font-size: 22px;
    line-height: 26px;
    color: #1f5e79;
    margin-bottom: 15px;
    font-weight: 600;
    margin-top: 0;
}

ul.cbp_tmtimeline {
    margin: 45px 0 45px 0;
    padding: 0;
    list-style: none;
    position: relative;
}

.cbp_tmtimeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #1f5e79;
    left: 12.5%;
    margin-left: -2px;
}

.cbp_tmtimeline>li {
    position: relative;
}

.cbp_tmtimeline>li .cbp_tmtime {
    display: block;
    width: 18%;
    padding-right: 80px;
    position: absolute;
}

.cbp_tmtimeline>li .cbp_tmtime span {
    display: block;
    text-align: right;
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
    font-size: 0.9em;
    color: #1f5e79;
    margin-bottom: 0;
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
    font-size: 18px;
    font-weight: 500;
}

.cbp_tmtimeline>li .cbp_tmicon {
    width: 48px;
    height: 48px;
    font-weight: 500;
    background-color: #fff;
    text-transform: none;
    font-size: 24px;
    line-height: 50px;
    position: absolute;
    color: #1f5e79;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #1f5e79;
    text-align: center;
    left: 12.5%;
    top: 0;
    margin: 0 0 0 -25px;
}

.cbp_tmtimeline>li .cbp_tmlabel {
    margin: 0 0 15px 18%;
    background: #f9fcff;
    padding: 20px;
    position: relative;
    /* -webkit-border-radius: 5px;
    -moz-border-radius: 5px; */
    border-radius: 10px;
}

.cbp_tmtimeline>li .cbp_tmlabel h4 {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 16px;
}

.itinery_meals {
    margin-top: 15px;
}

/* ul.bullets {
    line-height: 1.8;
    margin: 0;
    padding: 0;
    list-style-type: none;
} */

.itinery_meals ul.bullets li {
    display: inline-block;
    margin-right: 10px;
}

.itinery_meals ul.bullets li:first-child {
    padding-left: 0px;
}

.itinery_meals ul.bullets li:last-child {
    margin-right: 0px;
}

ul.bullets li i,
ul.bullets li svg {
    font-size: 14px;
}

.itinery_meals ul.bullets li:first-child .meal_icon {
    font-size: 16px;
    color: #043379;
}

.itinery_meals ul.bullets li {
    display: inline-block;
    margin-right: 10px;
}

.itinery_meals ul.bullets li:last-child {
    margin-right: 0px;
}

.add-activity-card {
    width: 100%;
    padding: 15px;
    border-radius: 4px;
    background-image: linear-gradient(259deg, rgba(204, 245, 242, 0.5019607843), rgba(212, 225, 158, 0.5019607843));
    text-align: center;
    margin-top: 14px;
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    margin-bottom: 0;
    font-size: 13px;
}

.btnactv {
    background: #95c32c;
    border: 1px solid #95c32c;
    padding: 5px 16px;
    display: inline-block;
    border-radius: 50px;
    margin-top: 10px;
    color: #fff;
    transition: all .5s ease-in-out;
}

.viewd_pop .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 750px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.viewd_pop .modal.right.fade .modal-dialog {
    right: -320px;
    /* -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out; */
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.viewd_pop .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border: none;
    border-radius: inherit;
}

.viewd_pop .modal-header {
    /* position: -webkit-sticky; */
    background: #fff;
    z-index: 9;
    position: sticky;
    top: 0;
}

.flx_se {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.flx_se .modal-title {
    width: 40%;
}

.cbp_tmtimeline>li .cbp_tmlabel h4 {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 16px;
}

.flx_se .sr_pop_vd {
    width: 60%;
}

.d-flex {
    /* display: -ms-flexbox; */
    display: flex;
}

.sr_pop_vd .d-flex {
    width: 100%;
    display: block;
}

.search_pop,
.sr_pop_vd .close {
    float: right;
}

.sr_pop_vd .close {
    margin-left: 15px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: #c8e0f1;
    box-shadow: initial;
    text-shadow: initial;
    border-radius: 50%;
    /* -webkit-animation: fadeIn .8s; */
    animation: fadeIn .8s;
    color: #5997f5;
    opacity: 1;
}

.viewd_pop .modal.right .modal-body {
    padding: 15px 15px 15px;
}

.box_pop_rpt {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin-bottom: 20px;
}

.pck_eq_img {
    width: 30%;
}

.pck_eq_txt {
    width: 70%;
    padding-left: 20px;
}

.badges_eq {
    display: block;
    text-align: right;
    margin-bottom: 10px;
}

.vl_txt {
    justify-content: space-between;
    align-items: end;
}

.dssl_s {
    width: 250px;
}

.dssl_s>h3 {
    margin: 0;
    font-size: 15px;
    margin-bottom: 5px;
}

.read_hd>p {
    font-size: 10px;
    height: 28px;
    overflow: hidden;
    margin: 0;
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    margin-bottom: 0;
    font-size: 13px;
}

.charges_xs {
    position: relative;
    top: 20px;
}

.pr_xs {
    display: block;
    font-size: 20px;
    margin: 0;
    font-weight: bold;
}

.perPerson {
    font-size: 12px;
    margin: 0;
    display: block;
    color: #919191;
}

.charges_xs>a {
    background: #95c32c;
    border: 1px solid #95c32c;
    padding: 5px 16px;
    display: inline-block;
    border-radius: 50px;
    margin-top: 10px;
    color: #fff;
    transition: all .5s ease-in-out;
}

.charges_xs>a {
    background: #95c32c;
    border: 1px solid #95c32c;
    padding: 5px 16px;
    display: inline-block;
    border-radius: 50px;
    margin-top: 10px;
    color: #fff;
    transition: all .5s ease-in-out;
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.makeFlex {
    /* display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox; */
    display: flex;
}

.makeFlex.column {
    -webkit-box-orient: vertical;
    /* -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -ms-flex-direction: column; */
    flex-direction: column;
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    margin-bottom: 0;
    font-size: 13px;
}

.font12 {
    font-size: 12px;
    line-height: 16px;
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    margin-bottom: 0;
    font-size: 13px;
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    margin-bottom: 0;
    font-size: 13px;
}

.cbp_tmtimeline>li .cbp_tmlabel:after {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #f9fcff;
    border-width: 10px;
    top: 15px;
}

.include .inclusion_icon {
    color: #95c32c;
    /* font-size: 14px; */
}

.divvv {
    display: flex;
    gap: 10px;
}

.exclude li .exclusion_icon {
    color: red;
}

.hotel_row {
    margin-bottom: 20px;
}

.padding5px {
    padding: 0 5px;
}

.packagedethotelimg {
    margin-bottom: 0;
    position: relative;
}

.zoomicon {
    position: absolute;
    right: 0;
    bottom: 0;
}

.zoomicon img {
    width: 22px !important;
    height: 21px !important;
}

.packagedethotelimg img {
    width: 100%;
    height: 90px;
}

.row_margin_5 {
    margin: 0px -5px;
}

.inner_hotel_pack {
    margin-left: 10px;
}

.packagelistboxheading {
    margin: 0;
    padding: 0;
}

.packagelistboxheading h3 {
    font-weight: 500;
    font-size: 15px;
    color: #3b3e3b;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 0;
}

.packagelistboxheading a {
    color: #3b3e3b;
    text-decoration: none;
}

.starmargin {
    margin: 10px 0;
}

.textblack13cont {
    font-weight: 400;
    font-size: 13px;
    color: #3b3e3b;
    line-height: 18px;
}

.topmargin10px {
    margin-top: 10px;
}

.textblack13bold {
    font-weight: 700;
    font-size: 13px;
    color: #3b3e3b;
    line-height: 20px;
}

.textblue13 {
    font-size: 13px;
    color: #5091fa;
    line-height: 18px;
}

.includedtxt {
    font-size: 11px;
    color: #89ad3e;
    font-style: italic;
    line-height: 18px;
}

.includedtxt span {
    padding: 7px 12px;
    background: #f2f2f2;
    font-size: 14px;
    border-radius: 4px;
    color: #6e6e6e;
    font-style: normal;
    font-weight: 600;
}

.hotel_row {
    margin-bottom: 20px;
}

.booking_sidebar,
.pack_total_price_sec {
    margin: 0 !important;
    padding: 0 !important;
}

.booking_sidebar .inner_sidebar {
    background: #fff;
    border-radius: 2px;
    /* -webkit-box-shadow: 0 2px 4px 0 #c8c8c8;
    -moz-box-shadow: 0 2px 4px 0 #c8c8c8; */
    box-shadow: 0 2px 4px 0 #c8c8c8;
    padding: 5px 0px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.fare_rules {
    border: 1px solid #ccc;
    padding: 10px;
}

.booking_sidebar .inner_sidebar ul {
    position: relative;
    z-index: 1;
}

.booking_sidebar .inner_sidebar ul li {
    display: block;
    color: #000;
}

.booking_sidebar .inner_sidebar ul li span.price,
.booking_sidebar .inner_sidebar ul li span.childprices {
    float: right;
    display: inline-block;
    color: #000;
}

.booking_sidebar .inner_sidebar ul li span.price .rupee_icon {
    font-size: 12px;
    color: #000;
}

.package_price {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #fff;
}

.pack_price_sec .pack_price_txt {
    display: inline-block;
    width: 100%;
}

.package_price .pack_price_txt h4 {
    color: #464646;
    font-size: 28px;
    line-height: 23px;
    margin: 0px;
    text-align: center;
    font-weight: 600;
}

.package_price .pack_price_txt h4 sub {
    color: #888;
    font-size: 14px;
    font-weight: normal;
}

.booking_sidebar .booking-form-title {
    text-align: center;
    margin: 15px 0;
}

#datepicker-time-start_tour,
.booking_sidebar button {
    color: #697488;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 12px;
    text-align: left;
    outline: none;
    box-shadow: none;
    width: 100%;
    margin-bottom: 10px;
}

.price-type-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.price-type-item .type {
    width: 70%;
}

.price-type-item .type label {
    color: #697488;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
}

.price-type-item .type-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
}

.price-type-item .type-count span {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* svg:not(:root) {
    overflow: hidden;
} */

/* circle[Attributes Style] {
    cx: 10;
    cy: 10;
    r: 9.5;
    fill: white;
    stroke: rgb(162, 201, 73);
} */

/* :not(svg) {
    transform-origin: 0px 0px;
} */

/* .type-count-svg-path {
    fill: #a2c949;
} */

.price-type-item .type-count input {
    color: #212121;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -.04em;
    border: none;
    text-align: center;
    width: 100%;
}

.package_price .pack_book_btn {
    text-align: center;
}

.package_price .pack_book_btn a {
    background: #a1c848;
    color: #fff;
    font-weight: 600;
    padding: 16px 20px;
    cursor: pointer;
    outline: 0;
    border: 0;
    font-size: 15px;
    line-height: 16px;
    display: block;
    border-radius: 3px;
}






@media (min-width: 1200px) {
    .booking_sidebar .inner_sidebar ul li {
        font-size: 14px;
        line-height: 24px;
        padding: 4px 15px;
    }

    .booking_sidebar .inner_sidebar ul li span.price,
    .booking_sidebar .inner_sidebar ul li span.childprices {
        font-size: 14px;
        line-height: 18px;
    }
}

@media (max-width:768px) {
    .details_main .secondary_nav.stickybar {
        top: 75px;
    }

    .details_main .secondary_nav .nav-wrapper {
        width: 100%;
    }

    .download_icon .dnonelg {
        display: none;
    }

    .cbp_tmtimeline>li .cbp_tmtime span {
        display: none;
    }

    .details_main .pack_details .download_btn {
        gap: 5px;
    }
}

/* @media screen and (max-width: 47.2em) {
    .cbp_tmtimeline>li .cbp_tmtime {
        width: 100%;
        position: relative;
        padding: 0 0 20px 0;
    }

    .cbp_tmtimeline>li .cbp_tmicon {
        position: relative;
        float: right;
        left: auto;
        margin: -65px 5px 0 0px;
    }

    .cbp_tmtimeline>li .cbp_tmlabel {
        margin: 0 0 30px 0;
        padding: 1em;
    }

    .cbp_tmtimeline>li .cbp_tmlabel:after {
        right: auto;
        left: 20px;
        border-right-color: transparent;
        border-bottom-color: #eef3f7;
        top: -20px;
    }

    .cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
        border-right-color: transparent;
        border-bottom-color: #eef3f7;
    }

    .cbp_tmtimeline:before {
        display: none;
    }
    
}

@media screen and (max-width: 65.375em) {
    .cbp_tmtimeline>li .cbp_tmtime span:last-child {
        font-size: 1.5em;
    }
} */