.new_markup .card .card-header .card-title {
    color: #a1c849;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.new_markup .card .card-body .form-group {
    margin-bottom: 10px;
}

.new_markup .btn-theme-sec, .new_markup .btn-theme-sec:hover, .new_markup .btn-theme-sec:focus {
    background: #336D85 !important;
    border-color: #336D85 !important;
    color: #fff !important;
}

.new_markup .float-right {
    float: right !important;
}