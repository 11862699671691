.breadcrumb_container{
    margin-right: auto;
    margin-left: auto;
}

.breadcrumb_container .cus_breadcrumb{
    margin: 25px 0 12px 0px;
}

.breadcrumb_container ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.breadcrumb_container ul li{
    transition: all .3s;
}

.breadcrumb_container .cus_breadcrumb ul li{
    display: inline-block;
    margin-right: 6px;
    color: #a1c849;
    font-size: 14px;
    line-height: 18px;
}

.breadcrumb_container .cus_breadcrumb ul li a{
    display: block;
    color: #000;
}

.breadcrumb_container .cus_breadcrumb ul li a:hover{
    color: #a1c849;
}

.breadcrumb_container a{
    text-decoration: none;
    background-color: transparent;
}

.breadcrumb_container .cus_breadcrumb ul li span{
    display: block;
    color: #000;
}