.banner_search.tour_package_search {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    min-height: 85vh;
    display: flex;
    align-items: center;
}

.banner_search.tour_package_search:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #0000003b;
}

.tour_package_search .banner_search_box form.search_form #custom-search-input {
    /* float: left; */
    width: 100%;
    border-radius: 5px;
    /* overflow: hidden; */
}

.d-flex {
    /* display: -ms-flexbox; */
    display: flex;
    flex-wrap: wrap;
}

.tour_package_search .banner_search_box form.search_form .input_field {
    /* position: relative; */
    display: inline;
    width: 75%;
    float: left;
}

.tour_package_search .banner_search_box form.search_form .input_field .css-1jqq78o-placeholder {
    background: #fff !important;
    width: 100% !important;
    border: 0px !important;
    padding: 15px 28px 15px 15px !important;
    font-size: 18px !important;
    line-height: 21px !important;
    font-weight: 300 !important;
    color: grey !important;
    /* white-space: wrap;
    overflow: hidden; */
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.tour_package_search .banner_search_box form.search_form .input_field .input-box>.css-b62m3t-container+.map_icon {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 21px;
    color: #37b1c2;
}

.tour_package_search .banner_search_box form.search_form .search_btn {
    width: 25%;
    float: left;
}

.tour_package_search .banner_search_box form.search_form .search_btn button {
    background: #a1c849;
    width: 100%;
    border: 0px;
    outline: none;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    padding: 15px 30px 15px;
}








@media (min-width: 768px) {
    .banner_search.tour_package_search {
        padding: 100px 0px 80px;
    }
}

@media (max-width:992px){
    .tour_package_search .banner_search_box form.search_form .input_field {
        width: 100% !important;
        border-bottom: solid 1px #c1bdbd;
        margin-bottom: 5px;
    }

    .tour_package_search .banner_search_box form.search_form .search_btn {
        width: 100%;
        align-items: center;
        text-align: center;
        margin-inline: auto;
        /* float: left; */
    }
}