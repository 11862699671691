.brands-container{
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

.brands-container .mt40{
    margin-top: 40px;
}

.brands-container .text-center{
    text-align: center;
}

.brands-container .headingal h2{
    margin: 0;
    font-size: 30px;
    /* font-family: inherit; */
    font-weight: 500;
    line-height: 1.1;
    /* color: inherit; */
}

.brands-container .brand_slider{
    margin-top: 40px;
    margin-bottom: 40px;
}

.brands-container .blogo span{
    display: flex;
    width: 200px;
    background: #fff;
    margin: 0 5px;
    margin-bottom: 20px;
    border: 1px solid #f3f4ff;
    border-radius: 4px;
    padding: 10px;
    height: 120px;
    position: relative;
    text-align: center;
    vertical-align: top;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%);
    align-items: center;
    justify-content: center;
}

.brands-container .blogo span img{
    width: auto !important;
    transition: all .5s ease-in-out;
}

.brands-container .rps{
    max-width: 100%;
    max-height: 100%;
}