.tourpackage_page .package_tour_sec {
    padding: 0 0px 40px;
    background: #f4f9f8;
}

.tourpackage_page .package_tour_sec .explore_tour_package {
    background: #fff;
    padding: 40px 0px;
}

.tourpackage_page .package_tour_sec .explore_tour_package .india_tour_package {
    margin-bottom: 50px;
}

.tourpackage_page .package_tour_sec .text-center {
    text-align: center;
}

.tourpackage_page .package_tour_sec .resort_title h2 {
    font-size: 28px;
    line-height: 32px;
    color: #000;
    margin: 0px 0px 30px;
    font-weight: 600;
}

.tourpackage_page .package_tour_sec .resort_title p {
    font-size: 16px;
    line-height: 26px;
    color: #333;
    margin: 0px 0px 15px;
    font-weight: normal;
}

.tourpackage_page .package_tour_sec .package_intrnational_resort, .tourpackage_page .package_family_holiday {
    padding-top: 40px;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column:hover {
    box-shadow: 0 0.9px 1.5px rgba(0, 0, 0, 0.03), 0 3.1px 5.5px rgba(0, 0, 0, 0.08), 0 14px 25px rgba(0, 0, 0, 0.12);
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    background: #fff;
    box-shadow: 0 0.3px 0.4px rgba(0, 0, 0, 0.025), 0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow .3s;
    flex-direction: column;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-img-top {
    width: 100%;
    max-height: 250px;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body {
    width: 100%;
    padding: 20px;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .inner_content {
    width: 100%;
    padding: 5px 0 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .inner_content .card-title {
    color: #102a42;
    font-size: 21px;
    line-height: 24px;
    margin: 0px 0px 10px;
}

.tourpackage_page .package_tour_sec a, .tourpackage_page .package_tour_sec a:hover, .tourpackage_page .package_tour_sec a:focus {
    text-decoration: none;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .inner_content .card-text {
    color: #759136;
    font-size: 14px;
    line-height: 21px;
    margin: 0px 0px 10px;
    display: block;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .price_view_btns {
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
}

.tourpackage_page .package_tour_sec .flex {
    display: flex;
}

.tourpackage_page .package_tour_sec .items-baseline {
    align-items: baseline;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .price_view_btns .price {
    font-size: 22px;
    line-height: 32px;
    color: #759136;
    font-weight: 600;
}

.tourpackage_page .package_tour_sec .card-body .price_view_btns .price span.currency_symb {
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
    vertical-align: top;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .price_view_btns span.pr_perperson {
    color: #5b5b5b;
    margin-left: 6px;
    font-size: 15px;
    line-height: 21px;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .price_view_btns .incld_tax {
    color: #5b5b5b;
    font-size: 14px;
    line-height: 18px;
    display: block;
    margin: 6px 0px 0px;
}

.tourpackage_page .package_tour_sec .package_card_list .package_card_column .card-body .price_view_btns a.view_btn {
    border: 1px solid #a1c849;
    color: #fff;
    border-radius: 4px;
    background-image: linear-gradient(to right, #a1c849, #759136);
    box-shadow: 0 4px 6px rgb(0 0 0 / 20%);
    font-size: 15px;
    line-height: 21px;
    padding: 8px 16px;
    display: block;
}

.tourpackage_page .package_tour_sec .explore_tour_package .owl-carousel .item {
    margin: 10px;
}

.tourpackage_page .package_tour_sec .explore_tour_package .destination_box {
    position: relative;
}

.tourpackage_page .package_tour_sec .explore_tour_package .destination_box a {
    display: block;
}

.tourpackage_page .package_tour_sec .explore_tour_package .destination_box .destination_name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: url(https://tripoholidays.com/public/images/bg-transt.png) repeat-x top left;
    padding: 70px 20px 15px;
}

.tourpackage_page .package_tour_sec .international_tour_package{
    margin:20px;
}

.tourpackage_page .package_tour_sec .explore_tour_package .destination_box .destination_name span {
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}

.tourpackage_page .package_tour_sec .owl-carousel .owl-nav {
    position: absolute;
    top: calc(50% - 20px);
    margin: 0px;
    width: 100%;
}

.tourpackage_page .package_tour_sec .owl-carousel .owl-nav .owl-prev {
    float: left;
    margin-left: -20px;
}

.tourpackage_page .package_tour_sec .owl-carousel .owl-nav .owl-next {
    float: right;
    margin-right: -20px;
}

.tourpackage_page .package_tour_sec .owl-carousel .owl-nav button.owl-prev,
.tourpackage_page .package_tour_sec .owl-carousel .owl-nav button.owl-next
{
    padding: 6px 10px !important;
    color: #fff;
}

.tourpackage_page .package_tour_sec .owl-prev,
.tourpackage_page .package_tour_sec .owl-next {
  /* border: 1px solid #a1c849 !important; */
  /* color: #fff; */
  /* border-radius: 4px; */
  /* background-image: linear-gradient(to right, #a1c849, #759136) !important; */
  /* box-shadow: 0 4px 6px rgb(0 0 0 / 20%); */

  background: #759136 !important;
  /* padding: 6px 10px !important; */
  font-size: 16px !important;
  line-height: 21px !important;
  margin: 0px;
}

.tourpackage_page .package_tour_sec .owl-prev:hover,
.tourpackage_page .package_tour_sec .owl-next:hover {
  background-image: linear-gradient(to right, #759136, #a1c849);
}

@media (min-width: 1000px) {
    .tourpackage_page .package_tour_sec .owl-nav {
        display: block !important;
    }
}