.homepg .Section_root___gfoL {
  max-width: 100%;
}

.homepg .Section_root_black__EKtgb {
  background-color: black;
}

.homepg .SectionWithRays_root__hU_KI {
  position: relative;
  padding-top: 76px;
  overflow: hidden;
  border-top: 4px solid #a1c849;
  border-bottom: 4px solid #a1c849;
}
/* 
.homepg * {
  margin: 0;
  padding: 0;
} */

.homepg .Container_root__n_Nmn {
  /* --container-padding: var(--space-xl); */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1920px;
  padding-inline: 20px;
  /* padding-inline: var(--container-padding); */
}

.homepg .SectionWithRays_content__kReGf {
  padding-bottom: 360px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.homepg .SectionWithRays_header__ZI3zY {
  position: relative;
  z-index: 1;
}

.homepg .SectionWithRays_headerOverflow__bT_85 {
  padding-inline: 20px;
  margin-inline: calc(-1 * 20px);
  background-color: black;
}

.homepg .Typography_root_variant_h2__r93x3 {
  /* font-family: var(--font-secondary); */
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
}

.homepg .SectionWithRays_subtitle__EEq_P,
.homepg .SectionWithRays_title__5Jyzj {
  letter-spacing: 0.01em;
}

.homepg .SectionWithRays_subtitle__EEq_P {
  /* margin-bottom: var(--space-s); */
  font-size: 28px;
  line-height: 100%;
  color: #a1c849;
}

.homepg .Typography_root_variant_h0__vffgM,
.homepg .Typography_root_variant_h1__bpLmV {
  /* font-family: var(--font-secondary); */
  font-size: 44px;
  font-weight: 900;
  line-height: 40px;
}

.homepg .SectionWithRays_subtitle__EEq_P,
.homepg .SectionWithRays_title__5Jyzj {
  letter-spacing: 0.01em;
}

.homepg .SectionWithRays_title__5Jyzj {
  /* margin-bottom: var(--space-m); */
  font-size: 44px;
  line-height: 90%;
  color: white;
}

.homepg .Typography_root_variant_p1__rxFrh {
  /* font-family: var(--font-primary); */
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.homepg .SectionWithRays_description__l2k3W {
  color: white;
}

/* .homepg .SectionWithRays_description_isLast__heWzK,
.homepg .SectionWithRays_description_second__EGd9W {
  padding-block-end: var(--space-12xl);
} */

.homepg .BaseButton_root__2bBDd {
  display: inline-flex;
  width: max-content;
  height: 48px;
  /* font-family: var(--font-primary-bold); */
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  transition: color 0.2s ease, background 0.2s ease, box-shadow 0.2s ease;
  justify-content: center;
  padding-inline: 40px;
  align-items: center;
}

.homepg .BaseButton_root_variant_primary___xgRg {
  color: var(--color-white-100);
  background-color: #a1c849;
}

.homepg .SectionWithRays_button__kpWMA {
  width: 100%;
}

.homepg .SectionWithRays_photo__h0Xh5 {
  position: absolute;
  z-index: 2;
  bottom: -14%;
  width: 80%;
  min-width: 284px;
  max-width: 358px;
  height: 90%;
  max-height: 419px;
}

.homepg .SectionWithRays_photo__h0Xh5 {
  right: 0 !important;
  left: 50% !important;
  transform: translate(-50%, 6%);
}

.homepg .SectionWithRays_rays__4ZZVh {
  position: absolute;
  display: none;
}

.homepg .SectionWithRays_rays_isMobile__QdmHK {
  top: 180px;
  display: block;
  width: 618px;
  height: 825px;
}

.homepg .SectionWithRays_rays_isMobile__QdmHK {
  left: 56%;
  transform: translate(-50%);
}

.homepg .BaseButton_root__2bBDd:focus-visible,
.BaseButton_root__2bBDd:hover {
  outline: none;
}

.homepg .BaseButton_root_variant_primary___xgRg:focus-visible,
.homepg .BaseButton_root_variant_primary___xgRg:hover {
  background-color: #a1c849;
}

@media screen and (min-width: 375px) {
  .homepg .SectionWithRays_photo__h0Xh5 {
    bottom: -12%;
  }

  .homepg .SectionWithRays_rays__4ZZVh {
    top: 89px;
  }
}

@media screen and (min-width: 768px) {
  .homepg .SectionWithRays_root__hU_KI {
    position: relative;
    padding-bottom: 80px;
  }

  .homepg .SectionWithRays_content__kReGf {
    position: relative;
    padding-bottom: 0;
    grid-template-columns: 1fr 1fr;
  }

  .homepg .SectionWithRays_headerOverflow__bT_85 {
    padding-inline: 0;
    margin-inline: 0;
    background: transparent;
  }

  .homepg .Typography_root_variant_h2__r93x3 {
    font-size: 36px;
    line-height: 36px;
  }

  .homepg .Typography_root_variant_h0__vffgM,
  .homepg .Typography_root_variant_h1__bpLmV {
    font-size: 52px;
    line-height: 47px;
  }

  .homepg .SectionWithRays_title__5Jyzj {
    font-size: 52px;
  }

  .homepg .Typography_root_variant_p1__rxFrh {
    font-size: 16px;
    line-height: 20px;
  }

  .homepg .SectionWithRays_description_isLast__heWzK,
  .homepg .SectionWithRays_description_second__EGd9W {
    padding-block-end: 60px;
  }

  .homepg .SectionWithRays_button__kpWMA {
    width: max-content;
    font-size: 14px;
  }

  .homepg .SectionWithRays_photo__h0Xh5 {
    right: 0;
    left: 0;
    bottom: 0;
    width: 704px;
    height: 500px;
  }

  .homepg .SectionWithRays_photo__h0Xh5 {
    transform: translate(9%, 14%);
  }

  .homepg .SectionWithRays_rays__4ZZVh {
    top: -152px;
  }

  .homepg .SectionWithRays_rays__4ZZVh {
    transform: translate(-20%, -10%);
  }
}
