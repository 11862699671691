.print_export {
    float: left;
}

.ledger_report .print_export {
    margin-right: 0px;
}

.card_custom_menu ul,
.print_export ul {
    list-style-type: none;
    padding-left: 0px;
    margin: 0px;
}

.print_export ul li button {
    color: #fff;
    border-radius: 4px;
    display: block;
    border: none;
}

.print_export ul li.export button,.print_export ul li.export button:active {
    background: #ffb935;
}

.card_custom_menu ul li:last-child, .print_export ul li:last-child {
    margin-right: 0px;
}

.print_export ul li.print button, .print_export ul li.print button:active {
    background: #a1c849;
}

.search_ref_id {
    margin: 15px 0px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    padding: 15px 0px;
}

.text-right {
    text-align: right !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.common_report .cus_report_field {
    margin-bottom: 15px;
}

.trbg{
    /* background-color: #e8e8e8 !important; */
    --bs-table-bg:#e8e8e8;
}






@media (min-width: 768px) {
    .print_export {
        margin-right: 15px;
    }

    .card_custom_menu ul li, .print_export ul li {
        display: inline-block;
        margin-right: 10px;
    }

    .print_export ul li button {
        font-size: 14px;
        line-height: 21px;
        padding: 6px 15px;
    }
}