.bg_cldt {
    /* background-image: url(/public/images/bg_counter.jpg); */
    padding: 40px 0px;
    margin: 20px 0px;
    vertical-align: middle;
    position: relative;
    background-position: center;
    background-attachment: fixed;
}

.bg_cldt::before {
    background: #316B83;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
}

.stat-container .container {
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

/* .stat-container .row{
    margin-right: -15px;
    margin-left: -15px;
} */

.stat-container .col-sm-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.stat-container .countbox ul {
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;

}

.stat-container .d_flex {
    display: flex;
}

.stat-container .countbox ul li {
    width: 14%;
    text-align: center;
    column-gap: 10px;
    color: #fff;
    margin-bottom: 10px;
}

.stat-container ul li {
    transition: all .3s;
}

.stat-container .countbox .count {
    background: #ffffff1c;
    margin: 10px 5px;
    height: 100%;
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stat-container .countbox ul li img {
    height: 45px;
    width: 45px;
    margin: 0 auto 10px;
}

.stat-container .rps{
    max-width: 100%;
    max-height: 100%;
}

.stat-container img {
    vertical-align: middle;
}

.stat-container .countbox ul li h2{
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 10px;
}

.stat-container .countbox ul li p{
    text-transform: uppercase;
    font-size: 12px;
}

@media (max-width:480px) {
    .stat-container .countbox ul li{
        width: 50%;
    }
}

@media (max-width:768px) and (min-width:480px) {
    .stat-container .countbox ul li{
        width: 33%;
    }
}

@media (max-width:992px) and (min-width:768px) {
    .stat-container .countbox ul li{
        width: 20%;
    }
}