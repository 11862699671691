.adv-container{
    font-family: 'Montserrat', sans-serif !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.adv-container .mrggape{
    margin: 50px 0px;
}

.adv-container .text-center{
    text-align: center;
}

.adv-container .headingal h2{
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.adv-container .row{
    margin-right: -15px;
    margin-left: -15px;
}

.adv-container .col-sm-6{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.adv-container .rps{
    max-width: 100%;
    max-height: 100%;
    height: -webkit-fill-available;
}

.adv-container .flx_d{
    display: flex;
    flex-wrap: wrap;
}

.adv-container ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.adv-container .flx_d li{
    margin-bottom: 15px;
    display: flex;
    border-radius: 5px;
    column-gap: 10px;
    margin: 0 0 18px 0;
}

.adv-container ul li{
    transition: all .3s;
}

.adv-container .flx_d li span{
    font-size: 20px;
    /* text-align: center; */
    display: block;
}

.adv-container .flx_d li span .icon{
    width: 50px;
    height: 50px;
    background: #9fc647;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: auto;
}

.adv-container .flx_d li h3{
    font-size: 17px;
    color: #333;
    font-weight: 600;
    margin: 0;
}

.adv-container .flx_d li h3 span{
    color: #666;
    line-height: 1.3;
    font-size: 13px;
    display: block;
    font-weight: 400;
    margin-top: 5px;
}