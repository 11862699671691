.card .card-body .nav_custom_tabs {
    border-bottom: 0px;
}

.card .card-body .nav_custom_tabs li:last-child {
    margin-right: 0px;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.card .card-body .nav_custom_tabs li a {
    background: transparent;
    color: #000;
    border-radius: 4px;
    border: 1px solid #dee2e6;
}

.card .card-body .nav_custom_tabs li a.active,
.card .card-body .nav_custom_tabs li a:hover {
    background: #a1c849;
    color: #fff;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.common_report .cus_report_field {
    margin-bottom: 15px;
}

.text-right {
    text-align: right !important;
}

.commission_amount .amount_btn .cus_btn,
.common_report .generate_dsr_btn .cus_btn,
.common_report .ledger_btn .cus_btn,
.card_charges .charges_btn .cus_btn {
    display: inline-block;
    background: #a1c849;
    border: 0px;
    outline: none;
    font-size: 14px;
    line-height: 21px;
    padding: 9px 15px;
    border-radius: 4px;
    color: #fff;
}

.common_report h4.table_heading {
    background: #a1c849;
    margin: 0px;
    padding: 10px 10px;
    color: #fff;
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
}









@media (min-width: 768px) {
    .card .card-body .nav_custom_tabs {
        margin-bottom: 20px;
    }

    .card .card-body .nav_custom_tabs li {
        margin-right: 10px;
    }

    .card .card-body .nav_custom_tabs li a {
        font-size: 15px;
        line-height: 21px;
        padding: 10px 10px;
    }
}