.company_logos_sec {
    margin: 15px 0px 10px;
}

.card .card-body .profile_img_field {
    margin-bottom: 10px;
}

.company_logos_sec .profile_img_field {
    border: 3px solid #f5f5f5;
    border-radius: 15px;
    padding: 15px;
    text-align: center;
}

.company_logos_sec .profile_img_field .form-label {
    font-size: 21px;
    line-height: 24px;
    color: #000;
    display: block;
    font-weight: 500;
    margin: 0px 0px 10px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

.logo_update {
    width: auto;
    display: inline-block;
    position: relative;
    margin: 10px 0px 0px;
}

.logo_update a {
    background: #a1c849;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    display: block;
}

.refresh_icon{
    font-size: 19px;
}