.transfer_page .py-50 {
    padding: 50px 0;
}

.transfer_page .image_card {
    background: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.transfer_page .image_card img {
    border-radius: 10px 10px 0 0;
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.transfer_page .image_card .card_content {
    padding: 20px;
}

.transfer_page .text-center {
    text-align: center;
}

.transfer_page .image_card h3 {
    margin-top: 0;
    font-size: 22px;
}

.transfer_page .image_card p {
    font-size: 16px;
    line-height: 25px;
}